import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import {HashLink} from 'react-router-hash-link';
import {AriaRole, ReactNode} from "react";

interface AnimatedLinkProps {
    children: ReactNode;
    to: string;
    className?: string;
    onClick?: (e: any) => void;
    role?: AriaRole
    bounceEffect?: boolean;
    customScale?: number;
    initial?: {};
    delay?: number;
    hashLink?: boolean
    state?: any
    replaceLinkWithAHref?: boolean
    openLinkInNewTab?: boolean
}

export default function AnimatedLink(props: AnimatedLinkProps) {
    const {
        children, to, className, onClick, role,
        bounceEffect, customScale, initial, delay, hashLink, state,
        replaceLinkWithAHref, openLinkInNewTab
    } = props
    const MotionLink = motion.create(hashLink ? HashLink : Link);

    return <>
        {
            replaceLinkWithAHref ? <motion.a
                    className={className}
                    href={to}
                    whileHover={{scale: customScale ? customScale : 1.1}}
                    transition={bounceEffect ? {type: 'spring', stiffness: 400, delay: delay} : {delay: delay}}
                    role={role}
                    onClick={onClick}
                    initial={initial}
                    animate={{opacity: 1, x: 0}}
                >
                    {children}
                </motion.a> :
                <MotionLink
                    target={openLinkInNewTab ? "_blank" : undefined}
                    className={className}
                    to={to}
                    whileHover={{scale: customScale ? customScale : 1.1}}
                    transition={bounceEffect ? {type: 'spring', stiffness: 400, delay: delay} : {delay: delay}}
                    role={role}
                    onClick={onClick}
                    initial={initial}
                    animate={{opacity: 1, x: 0}}
                    state={state ?? {}}
                >
                    {children}
                </MotionLink>
        }

    </>
}