
import React, { useState } from 'react';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import MainDesign from "../../ui/MainDesign";
import SmallSquareLogo from "../../ui/logo/SmallSquareLogo";
import AnimatedButton from "../../ui/motion/AnimatedButton";

function getTranslation(errorMessage: string) {
    switch (errorMessage) {
        case 'User does not exist':
            return 'Użytkownik nie istnieje.';
        case 'Code mismatch exception':
            return 'Kod weryfikacyjny jest nieprawidłowy.';
        case 'Expired code':
            return 'Kod weryfikacyjny wygasł.';
        case 'Invalid parameter: Username':
            return 'Nieprawidłowy email.';
        case 'Password does not conform to policy: Password not long enough':
            return 'Podane hasło musi składać się co najmniej z 8 znaków.';
        case 'Password does not conform to policy: Password must have lowercase characters':
            return 'Podane hasło musi zawierać co najmniej jedną małą literę.';
        case 'Password does not conform to policy: Password must have uppercase characters':
            return 'Podane hasło musi zawierać co najmniej jedną dużą literę.';
        case 'Password does not conform to policy: Password must have numeric characters':
            return 'Podane hasło musi zawierać co najmniej jedną cyfrę.';
        case 'Attempt limit exceeded, please try after some time.':
            return 'Przekroczono liczbę prób. Spróbuj ponownie za chwilę.'
        case 'Username/client id combination not found.':
            return 'Podany adres email nie istnieje.'
        default:
            return errorMessage;
    }
}

export default function ForgotPasswordComponent() {
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [step, setStep] = useState(1); // Step 1: Request reset, Step 2: Confirm new password
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleForgotPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsSubmitting(true);

        try {
            await resetPassword({
                username: email,
            });
            setStep(2);
        } catch (error: any) {
            console.error('Error requesting password reset', error);
            setErrorMessage(error.message || 'Error requesting password reset');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleForgotPasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsSubmitting(true);

        try {
            await confirmResetPassword({
                username: email,
                confirmationCode: verificationCode,
                newPassword: newPassword
            });
            window.location.href = '/logowanie';
        } catch (error: any) {
            console.error('Error resetting password', error);
            setErrorMessage(error.message || 'Error resetting password');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            {step === 1 && (
                <MainDesign containerStyles='container py-28'>
                    <div className="mb-7 text-center mx-auto mw-md-lg">
                        <SmallSquareLogo width='58px' disableLink={true} />

                        <h2 className="font-heading mb-4 fs-7 mt-8">Zresetuj hasło</h2>
                        <p className="mb-0 fs-9 fw-medium text-secondary-light">
                            Podaj swój adres email, aby otrzymać kod weryfikacyjny.
                        </p>
                    </div>
                    <form className="mw-sm mx-auto" onSubmit={handleForgotPassword}>
                        <div className="mb-4 row g-6">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="mb-1 fw-medium text-white" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        className="form-control text-dark shadow"
                                        id="email"
                                        type="email"
                                        placeholder="Podaj swój email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="mb-3">
                                <p className="text-danger">{getTranslation(errorMessage)}</p>
                            </div>
                        )}
                        <div className="mb-4 row g-4">
                            <div className="col-12">
                                <AnimatedButton
                                    className="btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Wyślij kod weryfikacyjny
                                </AnimatedButton>
                            </div>
                        </div>
                        <p className="mb-0 fs-13 fw-medium text-white text-center">
                            <span>Pamiętasz hasło? </span>
                            <Link className="text-success link-success" to="/logowanie">
                                Zaloguj się
                            </Link>
                        </p>
                    </form>
                </MainDesign>
            )}

            {step === 2 && (
                <MainDesign containerStyles='container py-28'>
                    <div className="mb-7 text-center mx-auto mw-md-lg">
                        <h2 className="font-heading mb-4 fs-7">Zresetuj hasło</h2>
                        <p className="mb-0 fs-9 fw-medium text-secondary-light">
                            Wpisz kod weryfikacyjny wysłany na {email}. Jeżeli nie widzisz wiadomości, sprawdź folder
                            spam.
                        </p>
                    </div>
                    <form className="mw-sm mx-auto" onSubmit={handleForgotPasswordSubmit}>
                        <div className="mb-4 row g-6">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="mb-1 fw-medium text-white" htmlFor="verificationCode">
                                        Kod weryfikacyjny
                                    </label>
                                    <input
                                        className="form-control text-dark shadow"
                                        id="verificationCode"
                                        type="text"
                                        placeholder="Podaj kod weryfikacyjny"
                                        value={verificationCode}
                                        onChange={(e) => setVerificationCode(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="mb-1 fw-medium text-white" htmlFor="newPassword">
                                        Nowe hasło
                                    </label>
                                    <input
                                        className="form-control text-dark shadow"
                                        id="newPassword"
                                        type="password"
                                        placeholder="Podaj nowe hasło"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="mb-3">
                                <p className="text-danger">{getTranslation(errorMessage)}</p>
                            </div>
                        )}
                        {infoMessage && (
                            <div className="mb-3">
                                <p className="text-success">{infoMessage}</p>
                            </div>
                        )}
                        <div className="mb-6 row g-4">
                            <div className="col-12">
                                <AnimatedButton
                                    className="btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Zmień hasło
                                </AnimatedButton>
                            </div>
                        </div>
                        <p className="mb-0 fs-13 fw-medium text-white text-center">
                            <span>Nie otrzymałeś/aś kodu? </span>
                            <button
                                type="button"
                                className="text-success link-success btn btn-link p-0"
                                onClick={async () => {
                                    try {
                                        await resetPassword({username: email});
                                        setInfoMessage('Kod został wysłany ponownie. Sprawdź swoją skrzynkę pocztową. Pamiętaj, aby użyć kodu z najnowszej wiadomości.')
                                    } catch (err) {
                                        setInfoMessage('');
                                        console.error('Error resending code: ', err);
                                        setErrorMessage('Wystąpił błąd podczas wysyłania kodu. Spróbuj ponownie.');
                                    }
                                }}
                            >
                                Wyślij ponownie
                            </button>
                        </p>
                    </form>
                </MainDesign>
            )}
        </>
    );
}