import React from 'react';
import MainDesign from "../../ui/MainDesign";
import AdvantageItem from "../../ui/element/item/AdvantageItem";
import AnimatedLink from "../../ui/motion/AnimatedLink";

export default function IndexPricing() {
    return <>
        <MainDesign containerStyles='container py-20' id='cennik'>
            <div className="mb-12 text-center">
                            <span
                                className="badge mb-4 bg-success-dark text-success text-uppercase shadow">CENNIK</span>
                <h2 className="font-heading mb-6 fs-5 text-white" style={{letterSpacing: '-1px'}}>
                    Wybierz dla siebie najlepszą opcję.
                </h2>
            </div>
            <div className='row g-2 gy-8 g-md-12 mw-md-7xl mx-auto'>
                <div className='col-12 col-md-6'>
                    <div className="h-100 p-8 bg-light-dark rounded shadow text-center">
                        <div className="mb-7 d-flex flex-column align-items-center">
                            <h3 className="mb-2 fs-6 fw-semibold" style={{letterSpacing: '-1px'}}>Idealna na początek.</h3>
                            <p className="mb-8 fw-medium text-secondary-light">
                                Świetna opcja dla korepetytorów prowadzących sporadycznie zajęcia.
                            </p>
                                    <div className="fs-5 fw-semibold text-white">
                                        Zawsze bezpłatnie
                                    </div>
                        </div>
                        <AnimatedLink
                            className="mb-8 btn btn-lg btn-success fs-10 py-4 w-100 text-success-light shadow shadow shadow"
                            to="/rejestracja">Przetestuj już teraz</AnimatedLink>
                        <div className='text-start'>
                            <AdvantageItem
                                title={'Do 15 tablic miesięcznie.'}/>
                            <AdvantageItem title={'Do 3 uczniów.'}/>
                            <AdvantageItem title={'Tablice można edytować tylko przez 24h od ich utworzenia.'}/>
                            <AdvantageItem title='Nielimitowany przesył plików.*'/>
                            <AdvantageItem title='Synchronizacja w czasie rzeczywistym.'/>
                            <AdvantageItem title='Tablice przechowywane są przez 180 dni.'/>
                            <AdvantageItem title='Udostępniaj zarówno foldery, jak i pojedyncze tablice.'/>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <div className="h-100 p-8 bg-light-dark rounded shadow text-center">
                        <div className="mb-4 d-flex flex-column align-items-center">
                            <h3 className="mb-2 fs-6 fw-semibold" style={{letterSpacing: '-1px'}}>Wszystko,
                                czego potrzebujesz.</h3>
                            <p className="mb-2 fw-medium text-secondary-light">
                                Najlepsza opcja dla osób pracujących regularnie z wieloma uczniami.
                            </p>

                            <div className="d-flex">
                                <div className="fs-7 fw-bold mt-1 pe-1 text-white">PLN</div>
                                <div className="fs-3 fw-semibold">
                                    <span className='text-white'>29.99</span>
                                    <span className="fs-11 text-secondary-light">/miesiąc</span>
                                </div>
                            </div>

                        </div>
                        <AnimatedLink
                            className="mb-8 btn btn-lg btn-success fs-10 py-4 w-100 text-success-light shadow shadow shadow"
                            to="/rejestracja">Przetestuj już teraz</AnimatedLink>
                        <div className='text-start'>
                            <AdvantageItem title={'Nielimitowana ilość tablic.'}/>
                            <AdvantageItem title={'Nielimitowana ilość uczniów.'}/>
                            <AdvantageItem title='Tablice można edytować przez cały czas.'/>
                            <AdvantageItem title='Nielimitowany przesył plików.*'/>
                            <AdvantageItem title='Synchronizacja w czasie rzeczywistym.'/>
                            <AdvantageItem title='Tablice przechowywane są przez 180 dni.'/>
                            <AdvantageItem title='Udostępniaj zarówno foldery, jak i pojedyncze tablice.'/>
                        </div>
                    </div>
                </div>
                <p className='fw-medium text-white fs-13 mt-2'><i>* Ze względów bezpieczeństwa istnieje limit przesyłania plików, jednak jest on niemożliwy do przekroczenia przy standardowym korzystaniu z tablicy.</i></p>
            </div>
            </MainDesign>
    </>

}

