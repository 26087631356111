import React, {useEffect} from 'react';
import './App.css';
import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";
import LoginPage from "./pages/guest/LoginPage";
import RegisterPage from "./pages/guest/RegisterPage";
import Whiteboard, {loader as whiteboardLoader} from "./pages/common/Whiteboard";
import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/ErrorPage";
import {redirectIfLoggedIn, redirectIfNotAdmin, tokenLoader} from "./util/AuthUtil";
import IndexPage from "./pages/common/IndexPage";
import StudentPage, {action as boardAction, loader as boardLoader} from "./pages/tutor/StudentPage";
import {HelmetProvider} from "react-helmet-async";
import {ToastProvider} from "./components/ui/toast/ToastContext";
import {action as tutorAction, loader as tutorLoader} from "./components/tutor/TutorIndex";
import ForgotPasswordPage from "./pages/guest/ForgotPasswordPage";
import PrivacyPolicyPage from "./pages/common/regulation/PrivacyPolicyPage";
import RegulationsPage from "./pages/common/regulation/RegulationsPage";
import TransactionsPage, {loader as transactionsLoader} from "./pages/tutor/TransactionsPage";
import DemoPage from "./pages/guest/DemoPage";
import LoginError from "./pages/guest/LoginError";
import AdminIndexPage, {loader as metricsLoader} from "./pages/admin/AdminIndexPage";
import AdminNewsletterPage, {action as sendNewsletterAction} from "./pages/admin/AdminNewsletterPage";
import AdminCodesPage, {action as codesAction, loader as codesLoader} from "./pages/admin/offer/AdminCodesPage";
import AdminOfferPage, {action as createTemporaryUserAction} from "./pages/admin/offer/AdminOfferPage";
import CancelNewsletterPage, {loader as cancelNewsletterLoader, action as cancelNewsletterAction} from "./pages/common/CancelNewsletterPage";
import ReferralPage, {loader as referralLoader} from "./pages/tutor/ReferralPage";

const router = createBrowserRouter([
    {
        path: '/admin',
        element: <RootLayout/>,
        errorElement: <ErrorPage/>,
        loader: redirectIfNotAdmin,
        id: 'adminRoot',
        children: [
            {
                index: true,
                element: <AdminIndexPage/>,
                loader: metricsLoader
            },
            {
                path: 'newsletter',
                element: <AdminNewsletterPage/>,
                action: sendNewsletterAction
            },
            {
                path: 'oferta',
                element: <AdminOfferPage/>,
                action: createTemporaryUserAction
            },
            {
                path: 'oferta/kody',
                element: <AdminCodesPage/>,
                loader: codesLoader,
                action: codesAction
            }
        ]
    },
    {
        path: "/",
        element: <RootLayout/>,
        errorElement: <ErrorPage/>,
        loader: tokenLoader,
        shouldRevalidate: () => true,
        id: 'root',
        children: [
            {
                index: true,
                path: "/",
                element: <IndexPage/>,
                loader: tutorLoader,
                action: tutorAction
            },
            {
                path: 'logowanie',
                element: <LoginPage/>,
                loader: redirectIfLoggedIn,
            },
            {
                path: 'login-error',
                element: <LoginError/>
            },
            {
                path: 'demo',
                element: <DemoPage/>,
                loader: redirectIfLoggedIn,
            },
            {
                path: 'rejestracja',
                element: <RegisterPage/>,
                loader: redirectIfLoggedIn,
            },
            {
                path: 'logout',
                loader: () => {
                    return redirect('/')
                }
            },
            {
                path: 'zmien-haslo',
                element: <ForgotPasswordPage/>,
                loader: redirectIfLoggedIn,
            },
            {
                path: 'polityka-prywatnosci',
                element: <PrivacyPolicyPage/>,
            },
            {
                path: 'regulamin',
                element: <RegulationsPage/>,
            },
            {
                path: 't/:roomId',
                element: <Whiteboard/>,
                loader: whiteboardLoader,
                action: boardAction
            },
            {
                path: '/uczen/:studentId',
                element: <StudentPage/>,
                loader: boardLoader,
                action: boardAction
            },
            {
                path: 'subskrypcja',
                element: <TransactionsPage/>,
                loader: transactionsLoader
            },
            {
                path: 'anuluj-newsletter',
                element: <CancelNewsletterPage/>,
                loader: cancelNewsletterLoader,
                action: cancelNewsletterAction
            },
            {
                path: 'polecenia',
                element: <ReferralPage/>,
                loader: referralLoader
            },
        ]
    }
])

function App() {
    useEffect(() => {
        let error = new URLSearchParams(window.location.hash.substring(1)).get('error_description');
        if (error) {
            if (error.startsWith('PreSignUp failed with error')) {
                const errorCause = error.replace('PreSignUp failed with error ', '');
                window.location.href = `/login-error?error_description=${errorCause}`
            } else {
                window.location.href = '/login-error'
            }
        }
    }, []);

    return (
        <HelmetProvider>
            <ToastProvider>
                <RouterProvider router={router}/>
            </ToastProvider>
        </HelmetProvider>
    );
}

export default App;
