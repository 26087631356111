import {fetchAuthSession, signOut, signInWithRedirect} from "aws-amplify/auth";

export interface TokenPayload {
    aud: string
    auth_time: number
    'cognito:groups': string[]
    'cognito:username': string
    'custom:confirmationCode': string
    email: string
    email_verified: boolean
    event_id: string
    exp: number
    iat: number
    iss: string
    jti: string
    name: string
    origin_jti: string
    sub: string
    token_use: string
}

export class AuthService {
    public static async getCurrentlyLoggedInUser(): Promise<TokenPayload | null> {
        // otherwise it returns null on first call...
        await new Promise(resolve => setTimeout(resolve, 1));
        const session = await fetchAuthSession();
        return session ? (session.tokens?.idToken?.payload as unknown as TokenPayload): null;
    }

    public static async facebookLogin(): Promise<void> {
        await signInWithRedirect({provider: 'Facebook'})
    }

    public static async signOut(): Promise<void> {
        return signOut();
    }
}