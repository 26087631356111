import {RoomSnapshot} from "@tldraw/sync";
import {DefaultPageMenu, TLComponents, Tldraw, TldrawUiButton, TLStoreSnapshot, useEditor} from "tldraw";
import React from "react";
import {CustomMainMenu} from "./CustomMainMenu";
import {BoardResponse} from "../../../model/tutor/Board";

interface ReadonlyBoardProps {
    boardResponse: BoardResponse
}
export default function ReadonlyBoard(props: ReadonlyBoardProps) {
    const {boardResponse} = props;

    function CustomPageMenu() {
        return (
            <>
                <div>
                    <DefaultPageMenu/>
                    <p className='mb-2 p-1' style={{width: '250px', marginLeft: '-28px'}}>Ta tablica jest tylko do
                        odczytu, ponieważ minęły 24 godziny od jej utworzenia.</p>
                </div>
                {
                    boardResponse.isTutor && <TldrawUiButton
                        type="menu"
                        onClick={() => window.location.href = '/subskrypcja'}
                        title={'abc'}
                        className="tlui-page-menu__trigger"
                        style={{
                            width: '170px',
                            paddingLeft: '0px',
                            marginLeft: '-106px',
                            marginTop: '1px',
                            marginRight: '0px',
                            paddingRight: '0px',
                            cursor: 'pointer'
                        }}>
                        <div className='text-success text-decoration-underline'>
                            Zarządzaj subskrypcją
                        </div>
                    </TldrawUiButton>
                }

            </>
        )
    }

    function getComponents(): TLComponents {
        return {
            ActionsMenu: null,
            MainMenu: () => {
                const editor = useEditor();
                return CustomMainMenu(editor, boardResponse.board.title, boardResponse.isTutor, "Powrót", () => {
                    window.location.href = `/uczen/${boardResponse.board.studentId}`
                });
            },
            PageMenu: CustomPageMenu,
        }
    }

    return <>
        <Tldraw
            snapshot={roomSnapshotToSnapshot(boardResponse.readonlySnapshot!!)}
            components={getComponents()}
            inferDarkMode={true}
            onMount={(editor) => {
                editor.updateInstanceState({isReadonly: true})
            }}
        />
    </>
}


function roomSnapshotToSnapshot(snapshot: RoomSnapshot): TLStoreSnapshot {
    return {
        schema: snapshot.schema!,
        store: Object.fromEntries(snapshot.documents.map((doc) => [doc.state.id, doc.state])) as any,
    }
}