import {
    DefaultQuickActions,
    DefaultQuickActionsContent,
    Editor, InstancePresenceRecordType,
    TLComponents,
    Tldraw,
    TldrawUiMenuItem,
    TLUiActionsContextType,
    TLUiOverrides,
    TLUiTranslationKey,
    useEditor
} from "tldraw";
import 'tldraw/tldraw.css'
import React, {useRef} from "react";
import BoardInfoModal from "../../tutor/board/BoardInfoModal";
import BoardShareModal from "../../tutor/board/BoardShareModal";
import {CustomMainMenu} from "../../common/whiteboard/CustomMainMenu";
import {CustomGrid} from "../../common/whiteboard/CustomGrid";

const USER_NAME = 'siemanko'
const MOVING_CURSOR_SPEED = 0.25 // 0 is stopped, 1 is full send
const MOVING_CURSOR_RADIUS = 100
const CURSOR_CHAT_MESSAGE = 'Co dzisiaj porysujemy?'

export default function DemoWhiteboard() {
    const [shareBoardModalOpen, setShareBoardModalOpen] = React.useState(false);
    const [boardInfoModalOpen, setBoardInfoModalOpen] = React.useState(false);
    const editorRef = useRef<Editor | null>(null);

    function CustomQuickActions() {
        const editor = useEditor()
        return (
            <DefaultQuickActions>
                {/*@ts-ignore*/}
                <DefaultQuickActionsContent/>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon="share-1" onSelect={() => setShareBoardModalOpen(true)}/>
                </div>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon="info-circle" onSelect={() => setBoardInfoModalOpen(true)}/>
                </div>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon={'fill-pattern'} onSelect={() => {
                        editor.updateInstanceState({isGridMode: !editor.getInstanceState().isGridMode})
                    }
                    }/>
                </div>
            </DefaultQuickActions>
        )
    }

    const overrides: TLUiOverrides = {
        actions(_editor, actions): TLUiActionsContextType {
            const newActions = {
                ...actions,
                'toggle-grid': {...actions['toggle-grid' as TLUiTranslationKey], kbd: 'x'},
                'toggle-dark-mode': {...actions['toggle-dark-mode' as TLUiTranslationKey], kbd: ''},
                'copy-as-png': {...actions['copy-as-png'], kbd: '$1'},
            }

            return newActions
        },
    }

    const components: TLComponents = {
        ActionsMenu: null,
        MainMenu: () => {
            const editor = useEditor();
            return CustomMainMenu(editor, "Testowa tablica", false);
        },
        QuickActions: CustomQuickActions,
        Grid: ({size, ...camera}) => {
            const editor = useEditor()

            return CustomGrid(editor, size, camera);
        }
    }

    const rRaf = useRef<any>(-1)

    return <>
        <div style={{height: '80vh'}}>
            <Tldraw
                components={components}
                autoFocus={true}
                inferDarkMode={true}
                overrides={overrides}
                onMount={(editor) => {
                    editorRef.current = editor;
                    // @ts-expect-error
                    window.editor = editor
                    if (editor.getCurrentPage().name === 'Page 1') {
                        editor.renamePage(editor.getCurrentPage(), "Strona 1")
                    }
                    editor.updateInstanceState({isGridMode: false})

                    const peerPresence = InstancePresenceRecordType.create({
                        id: InstancePresenceRecordType.createId(editor.store.id),
                        currentPageId: editor.getCurrentPageId(),
                        userId: 'peer-1',
                        userName: USER_NAME,
                        cursor: { x: 0, y: 0, type: 'default', rotation: 0 },
                        chatMessage: CURSOR_CHAT_MESSAGE,
                    })

                    editor.store.mergeRemoteChanges(() => {
                        editor.store.put([peerPresence])
                    })

                    // [b]
                    const raf = rRaf.current
                    cancelAnimationFrame(raf)

                    let done = false;

                    if (MOVING_CURSOR_SPEED > 0 || CURSOR_CHAT_MESSAGE) {
                        const k = 1000 / MOVING_CURSOR_SPEED; // Period of one circle in ms
                        const totalDuration = 2 * k; // Total time for two circles
                        const startTime = Date.now();

                        // @ts-ignore
                        function loop() {
                            if (done) {
                                return;
                            }
                            let cursor = peerPresence.cursor
                            let chatMessage = peerPresence.chatMessage

                            const now = Date.now()
                            const elapsedTime = now - startTime;

                            if (elapsedTime >= totalDuration) {
                                // Time to stop the cursor
                                done = true;
                                // Remove peer presence to make the cursor disappear
                                editor.store.mergeRemoteChanges(() => {
                                    editor.store.remove([peerPresence.id])
                                });
                                return;
                            }

                            if (MOVING_CURSOR_SPEED > 0) {
                                const t = (now % k) / k

                                cursor = {
                                    ...peerPresence.cursor,
                                    x: 150 + Math.cos(t * Math.PI * 2) * MOVING_CURSOR_RADIUS,
                                    y: 150 + Math.sin(t * Math.PI * 2) * MOVING_CURSOR_RADIUS,
                                }
                            }

                            if (CURSOR_CHAT_MESSAGE) {
                                const chatMessageK = 1000
                                const chatMessageT = (now % (chatMessageK * 3)) / chatMessageK
                                chatMessage =
                                    chatMessageT < 1
                                        ? ''
                                        : chatMessageT > 2
                                            ? CURSOR_CHAT_MESSAGE
                                            : CURSOR_CHAT_MESSAGE.slice(
                                                0,
                                                Math.ceil((chatMessageT - 1) * CURSOR_CHAT_MESSAGE.length)
                                            )
                            }

                            editor.store.mergeRemoteChanges(() => {
                                editor.store.put([
                                    {
                                        ...peerPresence,
                                        cursor,
                                        chatMessage,
                                        lastActivityTimestamp: now,
                                    },
                                ])
                            })

                            rRaf.current = editor.timers.requestAnimationFrame(loop)
                        }

                        rRaf.current = editor.timers.requestAnimationFrame(loop)
                    } else {
                        editor.store.mergeRemoteChanges(() => {
                            editor.store.put([{ ...peerPresence, lastActivityTimestamp: Date.now() }])
                        })
                        rRaf.current = editor.timers.setInterval(() => {
                            editor.store.mergeRemoteChanges(() => {
                                editor.store.put([{ ...peerPresence, lastActivityTimestamp: Date.now() }])
                            })
                        }, 1000)
                    }
                }}
            />
        </div>
        <BoardShareModal isOpen={shareBoardModalOpen} setOpen={setShareBoardModalOpen}
                         board={{
                             id: "example",
                             previewUrl: '',
                             description: "Na normalnej tablicy będziesz mieć możliwość edytowania tytułu i opisu.",
                             studentId: "example",
                             createdAt: new Date().toISOString(),
                             lastOpenedAt: new Date().toISOString(),
                             title: "Przykładowa tablica"
                         }} readonly={true}/>
        <BoardInfoModal isOpen={boardInfoModalOpen} setOpen={setBoardInfoModalOpen}
                        board={{
                            id: "example",
                            previewUrl: '',
                            description: "Na normalnej tablicy będziesz mieć możliwość edytowania tytułu i opisu.",
                            studentId: "example",
                            createdAt: new Date().toISOString(),
                            lastOpenedAt: new Date().toISOString(),
                            title: "Przykładowa tablica"
                        }}
                        isTutor={false}
                        onEditInfoClick={() => {
                        }}/>
    </>
}