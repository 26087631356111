import React from 'react';
import LoginComponent from '../../components/guest/login/LoginComponent';
import Meta from "../../components/common/Meta";

export default function LoginPage() {
  return (
    <React.Fragment>
        <Meta title='Logowanie'/>
      <LoginComponent />
    </React.Fragment>
  );
}

