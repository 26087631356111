import {AuthService, TokenPayload} from "../service/AuthService";
import {fetchAuthSession, JWT} from 'aws-amplify/auth';
import {redirect} from "react-router-dom";
import Clarity from "@microsoft/clarity";
import { v4 as uuidv4 } from 'uuid';

async function getRawIdToken(): Promise<JWT | null> {
    try {
        await new Promise(resolve => setTimeout(resolve, 1));
        const {idToken} = (await fetchAuthSession()).tokens ?? {};

        return idToken ? idToken : null;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function getRawIdTokenAsString(): Promise<string> {
    try {
        await new Promise(resolve => setTimeout(resolve, 1));
        const idToken = await getRawIdToken()

        return idToken ? idToken.toString() : '';
    } catch (err) {
        console.log(err);
        return '';
    }
}

export async function tokenLoader({request}: { request: Request }): Promise<TokenPayload | null> {
    const user = await AuthService.getCurrentlyLoggedInUser()
    return user ?? null;
}

export async function redirectIfNotLoggedIn(): Promise<Response | null> {
    const token = await getRawIdTokenAsString();

    if (!token) {
        return redirect('/logowanie');
    }
    return null;
}

export async function redirectIfLoggedIn(): Promise<Response | null> {
    const token = await getRawIdTokenAsString();

    if (token) {
        return redirect('/');
    }
    return null;
}

export async function redirectIfNotAdmin(): Promise<Response | TokenPayload | null> {
    // Otherwise tokens are always undefined ...
    await new Promise(resolve => setTimeout(resolve, 1));

    const tokens = (await fetchAuthSession()).tokens;

    if (!tokens) {
        return redirect('/logowanie');
    }

    const rawGroups = tokens.accessToken.payload['cognito:groups'];

    if (!rawGroups) {
        return redirect('/');
    }

    const groups = rawGroups as string[]

    if (!groups.includes("Admin")) {
        return redirect('/');
    }

    const user = await AuthService.getCurrentlyLoggedInUser()

    Clarity.identify(user?.sub ?? uuidv4());

    return user ?? null;
}

export function hasAdminGroup(groups: string[]): boolean {
    return groups.includes("Admin");
}