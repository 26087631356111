import Heading from "../../ui/Heading";
import NumbersItem from "../../ui/element/item/NumbersItem";
import {Metrics} from "../../../model/admin/Metrics";
import {User} from "../../../model/tutor/User";
import AdminUsersTable from "./AdminUsersTable";

interface AdminNumbersProps {
    metrics: Metrics
    users: User[]
}

export default function AdminNumbers(props: AdminNumbersProps) {
    const {metrics, users} = props

    return <>
        <Heading title="Ogólne" badgeText="Statystyki"
                 subtitle="Ogólne statystyki związane z wykorzystaniem TablicyNaKorki"/>
        <div className='row g-16 mb-20'>
            <NumbersItem metricValue={metrics.totalBoardsCreated} metricTitle="Liczba wszystkich utworzonych tablic" adminPanel={true}/>
            <NumbersItem metricValue={metrics.totalBoards} metricTitle="Liczba wszystkich tablic" adminPanel={true}/>
            <NumbersItem metricValue={metrics.totalStudentsCreated} metricTitle="Liczba wszystkich utworzonych uczniów" adminPanel={true}/>
            <NumbersItem metricValue={metrics.totalStudents} metricTitle="Liczba wszystkich uczniów" adminPanel={true}/>
            <NumbersItem metricValue={metrics.totalUsers} metricTitle="Liczba użytkowników" adminPanel={true}/>
            <NumbersItem metricValue={metrics.totalIncome} metricTitle="Całkowity przychód" adminPanel={true}/>
        </div>

        <hr className='text-white'/>

        <AdminUsersTable users={users}/>
    </>
}