export interface Transaction {
    id: string
    email: string
    price: number
    description: string
    type: string
    status: "CREATED" | "CONFIRMED"
    startedAt: string
}

export function parseTransactionStatus(transaction: Transaction): string {
    const dateDiffInHours = (new Date().getTime() - new Date(transaction.startedAt).getTime()) / 1000 / 60 / 60;
    if (transaction.status === 'CONFIRMED') {
        return 'Zrealizowana'
    } else if (transaction.status === 'CREATED' && dateDiffInHours < 24) {
        return 'Rozpoczęta'
    } else if (transaction.status === 'CREATED' && dateDiffInHours >= 24) {
        return 'Niezrealizowana'
    }

    return 'Nieznana'
}

export interface CreateTransactionRequest {
    isMonthly: boolean
}