import FormInput from "../../ui/form/FormInput";
import React, {useState} from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";

interface RedeemPromoCodeFormProps {
    isSubmitting: boolean
    onClick: (code: string) => void;
}

export default function RedeemPromoCodeForm({isSubmitting, onClick}: RedeemPromoCodeFormProps) {
    const [code, setCode] = useState('');
    return <>
        <FormInput label="Wprowadź kod" placeholder='ABCD1234' value={code} onChange={(code) => setCode(code)}/>
        <AnimatedButton
            className="btn btn-success w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow mt-4"
            type="button"
            disabled={isSubmitting}
            onClick={() => onClick(code)}
        >
            Aktywuj
        </AnimatedButton>
    </>
}