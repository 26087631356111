import Heading from "../../ui/Heading";
import DemoWhiteboard from "./DemoWhiteboard";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import React from "react";

export default function DemoComponent() {
    return <>
        <Heading badgeText='uczniowie' title='Intuicyjne zarządzanie uczniami'
                 subtitle='Dodawaj wielu uczniów wraz ze wszystkimi niezbędnymi informacjami. Jednym przyciskiem udostępnisz również wszystkie tablice z danym uczniem.'
                 customTitleStyles='font-heading mb-4 fs-6 text-white'/>
        <div className='text-center mb-12'>
            <img className="img-fluid lazyload"
                 data-src="https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/demo/uczniowie.png"
                 style={{width: '100%', maxWidth: '750px'}} alt='demo'/>
        </div>
        <hr className='text-white mb-12'/>

        <Heading badgeText='tablice' title='Intuicyjne zarządzanie tablicami'
                 subtitle='Dodawaj wiele tablic oraz opisuj, co zostało przerobione. Porządek i prostota.'
                 customTitleStyles='font-heading mb-4 fs-6 text-white'/>
        <div className='text-center mb-12'>
            <img className="img-fluid lazyload"
                 data-src="https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/demo/tablice.png"
                 style={{width: '100%', maxWidth: '750px'}} alt='demo'/>
        </div>
        <div className="row justify-content-center">
            <div className="col col-md-auto">
                <AnimatedLink
                    className="btn btn-success w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow"
                    to="/rejestracja">Testuję już teraz</AnimatedLink></div>
        </div>
        <hr className='text-white mb-12'/>

        <Heading title='Przykładowa tablica'
                 subtitle="Po stworzeniu konta, tablica będzie otwarta na całym ekranie oraz będzie można na niej pracować w kilka osób. Poniższa tablica jest tylko poglądowa i zmiany na niej nie zostaną zapisane."
                 customTitleStyles='font-heading mb-4 fs-6 text-white'/>

        <DemoWhiteboard/>

        <div className="row justify-content-center mt-10">
            <div className="col col-md-auto">
                <AnimatedLink
                    className="btn btn-success w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow"
                    to="/rejestracja">Testuję już teraz</AnimatedLink></div>
        </div>
    </>
}