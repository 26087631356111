import {Await, defer, useLoaderData} from "react-router-dom";
import Meta from "../../components/common/Meta";
import MainDesign from "../../components/ui/MainDesign";
import React, {Suspense} from "react";
import LoadingPage from "../common/LoadingPage";
import ErrorContent from "../../components/common/ErrorContent";
import {redirectIfNotLoggedIn} from "../../util/AuthUtil";
import Heading from "../../components/ui/Heading";
import {UserService} from "../../service/UserService";
import {User} from "../../model/tutor/User";
import {CodeService} from "../../service/CodeService";
import {Referral} from "../../model/Code";
import ReferralDashboard from "../../components/tutor/referral/ReferralDashboard";
import ReferralList from "../../components/tutor/referral/ReferralList";


type LoaderData = {
    data: {
        user: User
        referrals: Promise<Referral[]>
    }

}

export default function ReferralPage() {
    const {data} = useLoaderData() as LoaderData;
    return <>
        <Meta title="Program poleceń"/>
        <MainDesign containerStyles='container py-28'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={data} errorElement={<ErrorContent/>}>
                    {([user, referrals]: [User, Referral[]]) => <>
                        <Heading title='Program poleceń' badgeText='Polecenia'/>
                        <ReferralDashboard user={user} referrals={referrals}/>
                        <hr className='text-white mb-10'/>
                        <Heading title='Polecone osoby' badgeText='Lista poleceń'
                                 subtitle='Poniżej zobaczysz osoby, ktory skorzystały z Twojego kodu.'/>
                        {
                            referrals.length === 0 ? <div className='text-warning text-center fw-medium fs-9'>Brak poleconych osób.</div> :
                                <ReferralList referrals={referrals}/>
                        }
                    </>}
                </Await>
            </Suspense>

        </MainDesign>
    </>
}

export async function getData() {
    return Promise.all([
        UserService.getUser(),
        CodeService.getUserReferrals()
    ])
}


export async function loader() {
    const response = await redirectIfNotLoggedIn();
    if (response) {
        return response;
    }
    return defer({
        data: getData()
    });
}