import Header from "../common/header/Header";
import React from "react";

export default function AdminHeader() {
    return <>
        <Header mainPage={{
            title: 'Dashboard',
            to: '/admin'
        }} topItems={[
            {
                title: 'Newsletter',
                to: '/admin/newsletter',
            },
            {
                title: 'Oferta',
                to: '/admin/oferta',
            }
        ]}
        adminPanel={true}/>
    </>
}