import React from "react";
import {User} from "../../../model/tutor/User";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import Heading from "../../ui/Heading";
import TooltipWithHoverItem from "../../ui/element/item/TooltipWithHoverItem";

interface AdminUsersTableProps {
    users: User[]
}

export default function AdminUsersTable(props: AdminUsersTableProps) {
    const {users} = props;

    function formatSubscriptionDate(subscriptionUntil: string | null | undefined): string {
        if (!subscriptionUntil) return "-";

        const currentDate: Date = new Date();
        const subscriptionDate: Date = new Date(subscriptionUntil);

        // Check if the date is valid
        if (isNaN(subscriptionDate.getTime())) return "-";

        // Calculate the difference in days
        const diffTime: number = currentDate.getTime() - subscriptionDate.getTime();
        const diffDays: number = diffTime / (1000 * 60 * 60 * 24);

        if (diffDays > 30) {
            return "-";
        } else if (diffDays > 0) {
            return "Nieaktywna";
        } else {
            return subscriptionDate.toISOString().split("T")[0]; // Return the date without time
        }
    }

    return <>
        <Heading title='Wszyscy użytkownicy' badgeText='Nauczyciele'/>
        <div style={{overflowX: "auto"}} className='text-center'>
            <table className="table bg-light-dark text-white px-1 text-center mx-auto fs-11 rounded"
                   style={{boxSizing: "unset", overflowX: "visible"}}>
                <thead>
                <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Imię</th>
                    <th scope="col">Info</th>
                    <th scope="col">Data dołączenia</th>
                    <th scope="col">Aktywność</th>
                    <th scope="col">Uczniowie</th>
                    <th scope="col">Tablice (miesięczne)</th>
                    <th scope="col">Transfer</th>
                    <th scope="col">Subskrypcja do</th>
                    <th scope="col">Akcja</th>
                </tr>
                </thead>
                <tbody>
                {
                    users.sort((u1, u2) => u2.createdAt.localeCompare(u1.createdAt)).map((user: User) => (
                        <tr key={user.id}>
                            <th scope="row">{user.email}</th>
                            <td>{user.name}</td>
                            <td>{user.comment ?
                                <TooltipWithHoverItem customBgHex='#F59E0B' text={user.comment ?? '-'}/> : '-'} </td>
                            <td>{user.createdAt.split("T")[0]}</td>
                            <td>{user.lastActivity?.split("T")[0]}</td>
                            <td>{user.totalNumberOfStudents}</td>
                            <td>{user.totalMonthlyBoards}</td>
                            <td>{user.totalMegabytesUploaded.toFixed(2)} MB</td>
                            <td>{formatSubscriptionDate(user.subscriptionUntil)}</td>
                            <td><AnimatedButton
                                className='btn btn-sm btn-success text-success-light shadow'>Edytuj</AnimatedButton>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    </>
}