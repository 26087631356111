import RawModal from "../../ui/modal/RawModal";
import React from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {useToast} from "../../ui/toast/ToastContext";
import CopyToClipIcon from "../../ui/element/CopyToClipIcon";
import {Board} from "../../../model/tutor/Board";

interface BoardShareModalProps {
    isOpen: boolean
    setOpen: (value: boolean) => void
    board: Board
    setBoardToShare?: React.Dispatch<React.SetStateAction<Board | null>>
    readonly?: boolean
}

export default function BoardShareModal(props: BoardShareModalProps) {
    const {isOpen, setOpen, board, setBoardToShare, readonly} = props;

    const {showMessage} = useToast();

    const shareLink = readonly? "https://tablicanakorki.pl/demo" : `${window.location.origin}/t/${board?.id}`;

    async function copyToClip() {
        await navigator.clipboard.writeText(shareLink);
        showMessage("Pomyślnie skopiowano.");
    }

    return (
        <>
            <RawModal
                isOpen={isOpen}
                setOpen={setOpen}
                title={'Udostępnij tablicę'}
                onClose={() => {
                    if (setBoardToShare) {
                        setBoardToShare(null)
                    }
                }}
                children={
                    <>
                        <p className="text-white fw-medium text-secondary-light">
                            Uwaga! Każda osoba z poniższym linkiem będzie miała dostęp do wyświetlania i edytowania
                            tablicy o nazwie <i>
                                {board?.title}
                            </i>
                            .
                        </p>
                        <div className="row justify-content-center g-2 mt-2">
                            <div className="col-12">
                                <div className="d-flex">
                                    <input
                                        type="text"
                                        className="form-control form-control-md flex-grow-1 bg-dark"
                                        value={shareLink}
                                        readOnly
                                        style={{ padding: '1rem 0.75rem', color: "white" }} // Custom padding for smaller input
                                    />
                                    <AnimatedButton
                                        className="btn btn-outline-light btn-sm ms-2 flex-grow-0"
                                        type="button"
                                        onClick={copyToClip}
                                        style={{ whiteSpace: 'nowrap' }} // Ensure text stays in one line
                                    >
                                        <CopyToClipIcon />
                                        <span>Skopiuj link</span>
                                    </AnimatedButton>
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
        </>
    );
}