import React, {ReactNode} from "react";

interface CustomSelectProps {
    children: ReactNode,
    value?: string,
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
    customStyles?: string,
    name?: string,
    disabled?: boolean
    grayColor?: boolean
}

export default function CustomSelect(props: CustomSelectProps) {
    const {children, value, onChange, customStyles, name, disabled, grayColor} = props;

    return <select
        disabled={disabled}
        id='modalInput2-2'
        className={`form-select shadow-sm border-0 bg-light-dark ${customStyles ? customStyles : 'select-md'}`}
        value={value}
        name={name}
        onChange={onChange}
        style={{
            color: disabled || grayColor ? 'light-gray' : 'white', // White text color
            WebkitAppearance: 'none', // Removes default arrow for WebKit browsers
            appearance: 'none', // Removes default arrow for other browsers
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><polygon fill="white" points="0,0 20,0 10,10"/></svg>')`, // Custom arrow
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '97% 66%',
            backgroundSize: '1em',
        }}
    >
        {children}
    </select>
}