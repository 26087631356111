import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {CreateTransactionRequest, Transaction} from "../model/tutor/Transaction";

export class TransactionService {

    public static async createTransaction(createTransactionRequest: CreateTransactionRequest): Promise<{ url: string }> {
        return HttpService.sendRequest<{ url: string }>(
            HttpMethod.POST,
            `/transaction`,
            JSON.stringify(createTransactionRequest),
            ContentType.JSON
        );
    }

    public static async getAllUserTransactions(): Promise<Transaction[]> {
        return HttpService.sendRequest<Transaction[]>(
            HttpMethod.GET,
            `/transaction`,
        );
    }
}