import React from 'react';
import Meta from "../../components/common/Meta";
import DemoComponent from "../../components/guest/demo/DemoComponent";
import MainDesign from "../../components/ui/MainDesign";

export default function DemoPage() {
  return (
    <React.Fragment>
        <Meta title='TablicaNaKorki.PL - Demo'/>
        <MainDesign containerStyles='container py-28'>
            <DemoComponent />
        </MainDesign>
    </React.Fragment>
  );
}

