import React from 'react';
import RegisterComponent from '../../components/guest/register/RegisterComponent';
import Meta from "../../components/common/Meta";


export default function RegisterPage() {
  return (
    <React.Fragment>
      <Meta title='Rejestracja'/>
      <RegisterComponent />
    </React.Fragment>
  );
}

