import React from 'react';
import Meta from "../components/common/Meta";
import GuestHeader from "../components/guest/GuestHeader";
import GuestFooter from "../components/guest/GuestFooter";
import ErrorContent from "../components/common/ErrorContent";

export default function ErrorPage() {
    return <>
        <Meta title='Tablica Na Korki' description='Jedyna tablica do korepetycji, jakiej potrzebujesz.' noIndex={true}/>
        <GuestHeader />
        <ErrorContent messageProps="Strona nie została znaleziona."/>
        <GuestFooter/>
    </>
}

