import React from 'react';
import Meta from "../../components/common/Meta";
import ForgotPasswordComponent from "../../components/guest/login/ForgotPasswordComponent";

export default function ForgotPasswordPage() {
  return (
    <React.Fragment>
        <Meta title='Zmiana hasła'/>
      <ForgotPasswordComponent />
    </React.Fragment>
  );
}

