import {Transaction} from "../../model/tutor/Transaction";
import {Await, defer, useLoaderData} from "react-router-dom";
import Meta from "../../components/common/Meta";
import MainDesign from "../../components/ui/MainDesign";
import React, {Suspense} from "react";
import LoadingPage from "../common/LoadingPage";
import ErrorContent from "../../components/common/ErrorContent";
import {redirectIfNotLoggedIn} from "../../util/AuthUtil";
import {TransactionService} from "../../service/TransactionService";
import Heading from "../../components/ui/Heading";
import TransactionList from "../../components/tutor/transaction/TransactionList";
import {UserService} from "../../service/UserService";
import {User} from "../../model/tutor/User";
import SubscriptionWarning from "../../components/tutor/transaction/SubscriptionWarning";


type LoaderData = {
    data: {
        transactions: Promise<Transaction[]>
        user: User
    }

}

export default function TransactionsPage() {
    const {data} = useLoaderData() as LoaderData;
    return <>
        <Meta title="Transakcje"/>
        <MainDesign containerStyles='container py-28'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={data} errorElement={<ErrorContent/>}>
                    {([transactions, user]: [Transaction[], User]) => <>
                        <Heading title='Zarządzaj subskrypcją' badgeText='Subskrypcja'/>
                        <SubscriptionWarning user={user} infoOnly={true}/>
                        <hr className='text-white mb-10'/>
                        <Heading title='Historia transakcji' badgeText='Transakcje'
                                 subtitle='Poniżej znajdziesz pełną historię swoich transakcji i zweryfikujesz ich status'/>
                        <TransactionList transactions={transactions}/>
                    </>}
                </Await>
            </Suspense>

        </MainDesign>
    </>
}

export async function getData() {
    return Promise.all([
        TransactionService.getAllUserTransactions(),
        UserService.getUser()
    ])
}


export async function loader() {
    const response = await redirectIfNotLoggedIn();
    if (response) {
        return response;
    }
    return defer({
        data: getData()
    });
}