import React, {useState} from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {TransactionService} from "../../../service/TransactionService";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import {User} from "../../../model/tutor/User";
import {hasSubscriptionExpired} from "../../../util/UserUtil";
import TooltipWithHoverItem from "../../ui/element/item/TooltipWithHoverItem";
import RedeemPromoCodeForm from "./RedeemPromoCodeForm";
import {CodeService} from "../../../service/CodeService";
import {useNavigate} from "react-router-dom";

interface SubscriptionWarningProps {
    user: User;
    infoOnly?: boolean;
}

export default function SubscriptionWarning({
                                                user,
                                                infoOnly
                                            }: SubscriptionWarningProps) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPromoCodeForm, setShowPromoCodeForm] = useState(false);
    const [promoCodeInfo, setPromoCodeInfo] = useState<string | null>(null);
    const [promoCodeActivationSuccess, setPromoCodeActivationSuccess] = useState<boolean | null>(null);

    const subscriptionExpired = hasSubscriptionExpired(user);

    const expirationDate = new Date(user.subscriptionUntil);

    const [isMonthly, setIsMonthly] = useState(true);

    const navigate = useNavigate();

    const formattedDate = expirationDate.toLocaleDateString("pl-PL", {
        weekday: "long", // Display the day of the week
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    async function createTransactionAndRedirect() {
        setIsSubmitting(true);
        const {url} = await TransactionService.createTransaction({isMonthly: isMonthly});
        window.location.href = url;
    }

    async function redeemPromoCode(code: string) {
        setIsSubmitting(true);
        setPromoCodeActivationSuccess(null);
        setPromoCodeInfo(null)
        let info;
        let success;
        try {
            const {message} = await CodeService.redeemPromoCode({code});
            info = message;
            success = true;
        } catch (error: any) {
            info = error.message || "Wystąpił błąd. Spróbuj ponownie.";
            success = false;
        }
        setPromoCodeActivationSuccess(success);
        setPromoCodeInfo(info);
        setIsSubmitting(false);
        navigate(".", {replace: true});
    }

    return (
        <>
            <h3 className={`${infoOnly ? "text-warning" : "text-danger"} text-center mb-4 fs-8`}>
                {
                    !subscriptionExpired && <>
                        {infoOnly ? '' : 'Uwaga!'} Twoja subskrypcja
                        wygasa {formattedDate.includes("wtorek") ? 'we' : 'w'} {formattedDate.replace("niedziela", "niedzielę").replace("sobota", "sobotę").replace("środa", "środę")}.
                    </>
                }

                {
                    subscriptionExpired && infoOnly && "Aktualnie korzystasz z bezpłatnej wersji."
                }
            </h3>
            <p className="text-white text-center mb-4 fs-11 fw-medium">
                {
                    subscriptionExpired && infoOnly && <>
                        Odnów subskrypcję, aby dodawać nielimitowaną ilość uczniów oraz tablic, a także uniknąć limitu
                        pisania na tej
                        samej tablicy tylko przez 24h.
                        <TooltipWithHoverItem
                            text='Bezpłatna wersja pozwala na posiadanie 3 uczniów i tworzenie w sumie 15 tablic miesięcznie. Tablice przechodzą w tryb tylko do odczytu po 24h od ich utworzenia.'/>

                    </>
                }
                {
                    !subscriptionExpired && (
                        <>
                            {infoOnly
                                ? "Jeżeli chcesz dalej korzystać z tablicy bez żadnych limitów, odnów subskrypcję przed upływem powyższego terminu."
                                : "Odnów subskrypcję w najbliższym czasie, aby móc dalej korzystać z tablicy bez żadnych limitów."
                            }

                            <TooltipWithHoverItem
                                text='Bezpłatna wersja pozwala na posiadanie 3 uczniów i tworzenie w sumie 15 tablic miesięcznie. Tablice przechodzą w tryb tylko do odczytu po 24h od ich utworzenia.'/>

                        </>
                    )
                }

            </p>
            {
                infoOnly && <div className='mb-6 mt-4'>
                    <div className="d-flex justify-content-center align-items-center">
                        <p className={`mb-0 fs-9 fw-medium ${isMonthly ? 'text-white fw-bold' : 'text-secondary-light'}`}>Rozliczenie
                            miesięczne</p>
                        <div className="mx-4" onClick={() => setIsMonthly((prev) => !prev)} style={{cursor: 'pointer'}}>
                            <div
                                className={`p-1 d-flex align-items-center justify-content-${isMonthly ? 'start' : 'end'} ${isMonthly ? 'bg-secondary' : 'bg-success'} rounded-pill`}
                                style={{width: 64, height: 28}}>
                                <div className="bg-white rounded-pill" style={{width: 20, height: 20}}/>
                            </div>
                        </div>
                        <p className={`mb-0 fs-9 fw-medium ${isMonthly ? 'text-secondary-light' : 'text-white fw-bold'}`}>Rozliczenie
                            roczne (-33%)</p>
                    </div>
                </div>
            }
            {
                infoOnly && <>
                    <p className='text-white text-center mb-4 fs-11 fw-medium'> Każdorazowe odnowienie subskrypcji
                        kosztuje <i>{isMonthly ? '29.99' : '239.99'}PLN</i> i przedłuża ją
                        o <i>{isMonthly ? '31 dni' : '365 dni'}</i>.</p>
                    {
                        user.discountedPrice && isMonthly &&
                        <p className='text-white text-center mb-4 fs-11 fw-medium'><b>Użyto kodu ze zniżką.
                            Odnowienie subskrypcji wyniesie Cię jednorazowo: {user.discountedPrice}PLN.</b></p>
                    }
                    {
                        user.redeemedReferralCode && !user.redeemedReferralCodeUsed && isMonthly &&
                        <p className='text-white text-center mb-4 fs-11 fw-medium'><b>Użyto kodu polecającego.
                            Następna subskrypcja zostanie odnowiona na 62 dni.</b></p>
                    }
                    {
                        (user.discountedPrice || (user.redeemedReferralCode && !user.redeemedReferralCodeUsed)) && !isMonthly &&
                        <p className='text-white text-center mb-4 fs-12 fw-medium'><i>‼️ Kody polecające działają tylko w
                            przypadku miesięcznej subskrypcji ‼️</i></p>
                    }
                </>

            }
            {
                (infoOnly || !subscriptionExpired) &&
                <div className={`mw-md-xs mx-auto justify-content-center ${infoOnly ? 'mb-4' : 'mb-8'}`}>
                    <div className="">
                        {
                            infoOnly && <>
                                <AnimatedButton
                                    className="btn btn-success w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow"
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={createTransactionAndRedirect}
                                >
                                    Odnów teraz
                                </AnimatedButton>
                                {
                                    isMonthly && <>
                                        <p className='text-success fw-medium fs-13 text-center mt-1 text-decoration-underline'
                                           style={{cursor: 'pointer', userSelect: 'none'}} onClick={() => {
                                            if (!isSubmitting) {
                                                setShowPromoCodeForm((prev) => !prev)
                                            }
                                        }}>
                                            Masz kod polecający?
                                        </p>
                                        {
                                            showPromoCodeForm && <>
                                                <RedeemPromoCodeForm onClick={redeemPromoCode} isSubmitting={isSubmitting}/>
                                                {
                                                    promoCodeInfo &&
                                                    <p className={`${promoCodeActivationSuccess ? 'text-success' : 'text-danger'} mt-2 fs-12`}>{promoCodeInfo}</p>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                        {
                            !infoOnly && !subscriptionExpired && <>
                                <AnimatedLink
                                    className="btn btn-warning w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow"
                                    to='/subskrypcja'
                                >
                                    Zarządzaj subskrypcją
                                </AnimatedLink>
                            </>
                        }
                    </div>
                </div>
            }

            {
                infoOnly &&
                <p className='text-white text-center mb-4 fs-12 fw-medium'>Potrzebujesz faktury? Napisz do nas na
                    maila <i>kontakt@tablicanakorki.pl</i> po dokonaniu płatności.</p>
            }
        </>
    );
};