import ErrorContent from "../../components/common/ErrorContent";
import Meta from "../../components/common/Meta";
import {useSearchParams} from "react-router-dom";

export default function LoginError() {
    const [searchParams] = useSearchParams();
    const error = searchParams.get('error_description');
    return <>
        <Meta title='Błąd logowania'/>
        <ErrorContent messageProps={error ?? 'Coś poszło nie tak podczas logowania...'}
                      customTryAgainButton={{title: 'Zaloguj się ponownie', link: '/logowanie'}}/>
    </>
}