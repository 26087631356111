import { useState, useEffect, useRef } from 'react';
import AnimatedButton from "../motion/AnimatedButton";

interface DropdownMenuElement {
    onClick: () => void;
    name: string;
}

interface DropdownMenuProps {
    elements: DropdownMenuElement[];
}

export default function DropdownMenu(props: DropdownMenuProps) {
    const { elements } = props;

    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLUListElement>(null);

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsMenuVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isMenuVisible && menuRef.current && dropdownRef.current) {
            const menuRect = menuRef.current.getBoundingClientRect();
            const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

            // If the menu overflows to the right, adjust its position
            if (menuRect.right > viewportWidth) {
                menuRef.current.style.left = 'auto';
                menuRef.current.style.right = '0';
            } else {
                menuRef.current.style.left = '';
                menuRef.current.style.right = '';
            }
        }
    }, [isMenuVisible]);

    return (
        <div className="position-relative" ref={dropdownRef}>
            <img
                src="https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/elements/three-dots-menu-white.png"
                alt="Menu"
                className="dropdown-toggle"
                style={{
                    marginLeft: 'auto',
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                }}
                onClick={toggleMenu}
            />
            {isMenuVisible && (
                <ul
                    ref={menuRef}
                    className="dropdown-menu show bg-light-dark"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        minWidth: 'auto',
                    }}
                >
                    {elements.map((element, index) => (
                        <li key={index}>
                            <AnimatedButton
                                className="dropdown-item text-white"
                                onClick={() => {
                                    element.onClick();
                                    setIsMenuVisible(false);
                                }}
                            >
                                {element.name}
                            </AnimatedButton>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}