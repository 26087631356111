import Meta from "../../components/common/Meta";
import React, {Suspense} from "react";
import MainDesign from "../../components/ui/MainDesign";
import {Await, defer, useLoaderData} from "react-router-dom";
import LoadingPage from "../common/LoadingPage";
import ErrorContent from "../../components/common/ErrorContent";
import {MetricsResponse} from "../../model/admin/Metrics";
import {AdminService} from "../../service/AdminService";
import AdminNumbers from "../../components/admin/dashboard/AdminNumbers";

type LoaderData = {
    metricsResponse: Promise<MetricsResponse>
}

export default function AdminIndexPage() {
    const {metricsResponse} = useLoaderData() as LoaderData;
    return <>
        <Meta title='Panel admina'/>
        <MainDesign containerStyles='py-20 container'>

            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={metricsResponse} errorElement={<ErrorContent/>}>
                    {(loadMetricsResponse: MetricsResponse) => <>
                        <AdminNumbers users={loadMetricsResponse.users} metrics={loadMetricsResponse.metrics}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadMetrics(): Promise<MetricsResponse> {
    return await AdminService.getMetrics();
}

export function loader() {
    return defer({
        metricsResponse: loadMetrics()
    });
}