import {motion} from "framer-motion";
import React from "react";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {AuthService, TokenPayload} from "../../../service/AuthService";
import {hasAdminGroup} from "../../../util/AuthUtil";

interface HeaderDropdownMenuProps {
    dropdownRef: React.RefObject<HTMLDivElement>;
    toggleDropdown: () => void;
    dropdownOpen: boolean;
    token?: TokenPayload
}

export default function HeaderDropdownMenu(props: HeaderDropdownMenuProps) {
    const {dropdownRef, toggleDropdown, dropdownOpen, token} = props;
    return <>
        <div className='col-auto' ref={dropdownRef}>
            <div className={`dropdown ${dropdownOpen ? 'show' : ''}`}>
                <motion.div whileHover={{scale: 1.2}}>
                    <div role='button' onClick={toggleDropdown}>
                        <img
                            className='mb-0 team-round-image'
                            src='https://produkacja.s3.eu-central-1.amazonaws.com/tnk-web/elements/user.png'
                            alt=''
                            style={{width: '50px', height: '50px'}}
                        />
                    </div>
                </motion.div>
                <div
                    className={`bg-light-dark dropdown-menu position-absolute ${dropdownOpen ? 'show' : ''}`}
                    style={{right: '-10px', top: '54px'}}
                    aria-labelledby="dropdownMenuLink"
                >
                    {
                        token && hasAdminGroup(token["cognito:groups"]) &&
                        <AnimatedLink className="text-white dropdown-item dropdown-item-custom shadow"
                                      to="/admin" onClick={toggleDropdown}
                                      customScale={1.05}>Panel Admina</AnimatedLink>
                    }
                    <AnimatedLink className="text-white dropdown-item dropdown-item-custom shadow"
                                  to="/" onClick={toggleDropdown}
                                  customScale={1.05}>Panel nauczyciela</AnimatedLink>
                    <AnimatedLink className="text-white dropdown-item dropdown-item-custom shadow"
                                  to="/subskrypcja" onClick={toggleDropdown}
                                  customScale={1.05}>Zarządzaj subskrypcją</AnimatedLink>
                    <AnimatedLink className="text-white dropdown-item dropdown-item-custom shadow"
                                  to="/polecenia" onClick={toggleDropdown}
                                  customScale={1.05}>Program poleceń</AnimatedLink>
                    <AnimatedButton className="text-white dropdown-item dropdown-item-custom"
                                    customScale={1.05} onClick={() => {
                        AuthService.signOut();
                        window.location.replace('/')
                    }}>
                        Wyloguj się
                    </AnimatedButton>
                </div>
            </div>
        </div>
    </>
}