export enum CodeType {
    "FREE_SUBSCRIPTION" = "FREE_SUBSCRIPTION",
    "DISCOUNT" = "DISCOUNT",
    "REFERRAL" = "REFERRAL" // you and your friend get 30 days of subscription for free
}

export interface Code {
    code: string,
    codeType: CodeType,
    createdAt: string,
    maxUsages: number,
    usages: number,
    expirationDate: string,
    comment: string,
    subscriptionDays?: number,
    discountedPrice?: number
    isActive: boolean
}

export interface CreateCodeRequest {
    code?: string
    codeType: CodeType
    maxUsages: number
    expirationDate?: string
    comment?: string
    subscriptionDays?: number,
    discountedPrice?: number
}

export interface ToggleCodeActiveRequest {
    code: string
}

export interface RedeemPromoCodeRequest {
    code: string
}

export interface RedeemPromoCodeResponse {
    message: string
}

export interface Referral {
    name: string,
    referredAt: string
}