import React, {useEffect, useState} from "react";
import {useSubmit} from "react-router-dom";
import DOMPurify from 'dompurify';
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import AnimatedButton from "../../../components/ui/motion/AnimatedButton";
import {useHandleDirtyForm} from "../../../hooks/useHandleDirtyForm";
import {NewsletterTypes} from "../../../model/admin/Newsletter";
import ConfirmModal from "../../ui/modal/ConfirmModal";

const LOCAL_STORAGE_NEWSLETTER_SUBJECT_KEY = 'newsletterSubject'
const LOCAL_STORAGE_NEWSLETTER_MESSAGE_KEY = 'newsletterMessage'

export default function AdminNewsletter() {
    const [subject, setSubject] = useState(localStorage.getItem(LOCAL_STORAGE_NEWSLETTER_SUBJECT_KEY) ?? '');
    const [email, setEmail] = useState('');
    const [joinedBefore, setJoinedBefore] = useState('');
    const [source, setSource] = useState('');
    const [message, setMessage] = useState(localStorage.getItem(LOCAL_STORAGE_NEWSLETTER_MESSAGE_KEY) ?? '');
    const initialNewsletterTypesState = Object.keys(NewsletterTypes).reduce((acc, key) => {
        acc[key as keyof typeof NewsletterTypes] = false;
        return acc;
    }, {} as Record<keyof typeof NewsletterTypes, boolean>);

    const [selectedNewsletterTypes, setSelectedNewsletterTypes] = useState(initialNewsletterTypesState);
    const [selectedNewsletterTypesToExclude, setSelectedNewsletterTypesToExclude] = useState(initialNewsletterTypesState);
    const [openSendToAllModal, setOpenSendToAllModal] = useState(false);

    const submit = useSubmit();
    const [_, setIsFormDirty] = useHandleDirtyForm();

    const [isMailingListToExcludeVisible, setIsMailingListToExcludeVisible] = React.useState(false);

    const {isSubmitting} = useSubmitFormAction('/admin/newsletter', null, () => {
        setSelectedNewsletterTypes(initialNewsletterTypesState);
        setSelectedNewsletterTypesToExclude(initialNewsletterTypesState);
        setOpenSendToAllModal(false);

        localStorage.removeItem(LOCAL_STORAGE_NEWSLETTER_SUBJECT_KEY);
        localStorage.removeItem(LOCAL_STORAGE_NEWSLETTER_MESSAGE_KEY);
    });

    useEffect(() => {
    }, [message]);

    function getSelectedTypesArray() {
        return Object.entries(selectedNewsletterTypes)
            .filter(([_, isSelected]) => isSelected)
            .map(([type]) => type);
    }

    function getSelectedTypesToExcludeArray() {
        return Object.entries(selectedNewsletterTypesToExclude)
            .filter(([_, isSelected]) => isSelected)
            .map(([type]) => type);
    }

    async function sendForm() {
        submit({
            action: "SEND_NEWSLETTER",
            subject: subject,
            message: message,
            email: email,
            source: source,
            joinedBefore: joinedBefore,
            types: getSelectedTypesArray(),
            typesToExclude: getSelectedTypesToExcludeArray()
        }, {
            method: "POST"
        });
    }

    const createMarkup = () => {
        return {__html: DOMPurify.sanitize(message)};
    };

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    function isFormValid() {
        return message.trim().length >= 10 && subject.trim().length >= 5 && (getSelectedTypesArray().length > 0 || isEmailValid);
    }

    const options = Object.keys(NewsletterTypes); // Assuming NewsletterType prop is an object

    function toggleCheckboxToAddNewsletter(option: keyof typeof NewsletterTypes) {
        setSelectedNewsletterTypes(prevState => ({
            ...prevState,
            [option]: !prevState[option]
        }));
    }

    function toggleCheckboxToExcludeMailingList(option: keyof typeof NewsletterTypes) {
        setSelectedNewsletterTypesToExclude(prevState => ({
            ...prevState,
            [option]: !prevState[option]
        }));
    }

    return <>
        <div className='row g-16 mt-4'>
            <div className='col-12 col-md-6'>
                <div className='mw-xl-2xl'>
                    <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
                      Newsletter
                    </span>
                    <h1
                        className='mb-6 font-heading fs-5 text-white'
                        style={{letterSpacing: '-1px'}}
                    >
                        Napisz wiadomość
                    </h1>
                    <p className='mb-8 fs-9 fw-medium text-secondary-light'>
                        Skorzystaj z formularza poniżej, aby napisać nową wiadomość email.
                    </p>
                    <div className="mb-3">
                        <label
                            className='mb-2 text-white fw-bold'
                            htmlFor='admin-newsletter-type'
                        >
                            Lista mailingowa
                        </label>
                        {options.map((option, index) => {
                            return (
                                <div className="form-check text-white" key={index}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`checkbox-${option}`}
                                        checked={selectedNewsletterTypes[option as keyof typeof NewsletterTypes]}
                                        onChange={() => toggleCheckboxToAddNewsletter(option as keyof typeof NewsletterTypes)}
                                    />
                                    <label style={{userSelect: "none"}} className="form-check-label"
                                           htmlFor={`checkbox-${option}`}>
                                        {NewsletterTypes[option as keyof typeof NewsletterTypes]}
                                    </label>
                                </div>
                            );
                        })}
                        <AnimatedButton
                            className={`btn btn-sm btn-outline-success mt-3 ${isMailingListToExcludeVisible ? 'mb-4' : 'mb-0'}`}
                            onClick={() => setIsMailingListToExcludeVisible(!isMailingListToExcludeVisible)}>{isMailingListToExcludeVisible ? "Ukryj wykluczenie" : "Wyklucz listy mailingowe"}</AnimatedButton>
                        {
                            isMailingListToExcludeVisible &&
                            <p className='text-white fw-bold mb-2'>Wyklucz poniższe listy mailingowe</p>
                        }
                        {
                            isMailingListToExcludeVisible && options.map((option, index) => {
                                return (
                                    <div className="form-check text-white" key={index}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`checkbox-${option}-exclude`}
                                            checked={selectedNewsletterTypesToExclude[option as keyof typeof NewsletterTypes]}
                                            onChange={() => toggleCheckboxToExcludeMailingList(option as keyof typeof NewsletterTypes)}
                                        />
                                        <label style={{userSelect: "none"}} className="form-check-label"
                                               htmlFor={`checkbox-${option}-exclude`}>
                                            {NewsletterTypes[option as keyof typeof NewsletterTypes]}
                                        </label>
                                    </div>
                                );
                            })}

                        <p className='mb-2 mt-3 fs-11 fw-medium text-secondary-light'>
                            {"Wysyłając maila, na dole należy dodać link do anulowania subskrypcji z wartością {{Cancel_Newsletter_Url}}. Zostanie on dynamicznie podmieniony."}
                        </p>
                        <p className='mb-2 mt-3 fs-11 fw-medium text-secondary-light'>
                            {"Wysyłając wiadomość, w której są 'Zarejestrowani użytkownicy (wszyscy)', nie dodajemy tego!!"}
                        </p>

                        <label
                            className='mb-1 mt-2 fw-medium text-white'
                            htmlFor='signInInput3-2'
                        >
                            Temat
                        </label>
                        <input
                            className='form-control text-secondary-dark shadow mb-3'
                            id='signInInput3-2'
                            type='text'
                            autoComplete="off"
                            placeholder='Wprowadź temat'
                            name='subject'
                            value={subject}
                            onChange={event => {
                                setSubject(event.target.value)
                                localStorage.setItem(LOCAL_STORAGE_NEWSLETTER_SUBJECT_KEY, event.target.value);
                            }}
                        />
                        <label
                            className='mb-1 mt-2 fw-medium text-white'
                            htmlFor='signInInput3-3'
                        >
                            Adres odbiorcy (zamiast listy mailingowej)
                        </label>
                        <input
                            className='form-control text-secondary-dark shadow mb-3'
                            id='signInInput3-3'
                            type='text'
                            autoComplete="off"
                            placeholder='Wprowadź adres email (opcjonalnie)'
                            name='email'
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                        />
                        <label
                            className='mb-1 mt-2 fw-medium text-white'
                            htmlFor='signInInput3-3'
                        >
                            Dołączono przed (data) (opcjonalnie ISO String, np. 2024-10-20)
                        </label>
                        <input
                            className='form-control text-secondary-dark shadow mb-3'
                            id='signInInput3-3'
                            type='text'
                            autoComplete="off"
                            placeholder='Wprowadź datę w formacie ISO String'
                            name='email'
                            value={joinedBefore}
                            onChange={event => setJoinedBefore(event.target.value)}
                        />
                        <label
                            className='mb-0 mt-2 fw-medium text-white'
                            htmlFor='signInInput3-4'
                        >
                            Nadawca maila. Należy
                            podawać {"<nadawca@produkacja.pl>"} na końcu!
                        </label>
                        <label
                            className='mb-1 mt-1 fw-medium text-white'
                            htmlFor='signInInput3-4'
                        >
                            Przykład: "{"Marysia z TablicaNaKorki <kontakt@tablicanakorki.pl>"}"
                        </label>
                        <input
                            className='form-control text-secondary-dark shadow mb-3'
                            id='signInInput3-4'
                            type='text'
                            autoComplete="off"
                            placeholder='Tablica na Korki <kontakt@tablicanakorki.pl> (opcjonalne)'
                            name='source'
                            value={source}
                            onChange={event => setSource(event.target.value)}
                        />
                        <label
                            className='mb-1 fw-medium text-white'
                            htmlFor='email-input'
                        >
                            Zawartość maila
                        </label>
                        <textarea className="form-control mb-4 text-secondary-dark" name="field-name"
                                  rows={5}
                                  placeholder="Zacznij pisać..." value={message} id="email-input"
                                  onChange={event => {
                                      setIsFormDirty(true);
                                      setMessage(event.target.value);
                                      localStorage.setItem(LOCAL_STORAGE_NEWSLETTER_MESSAGE_KEY, event.target.value);
                                  }}></textarea>
                    </div>
                    <div className='row g-4'>
                        <div className='col-12 col-md-auto'>
                            <AnimatedButton
                                className='btn btn-lg btn-success w-100 text-success-light shadow'
                                onClick={() => setOpenSendToAllModal(true)}
                                disabled={!isFormValid() || isSubmitting}
                            >
                                Wyślij
                            </AnimatedButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6'>
                <div className='mw-md-2xl'>
                            <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
                              Podgląd
                            </span>
                    <h1
                        className='mb-6 font-heading fs-5 text-white'
                        style={{letterSpacing: '-1px'}}
                    >
                        Zobacz podgląd
                    </h1>
                    <p className='mb-8 fs-9 fw-medium text-secondary-light'>
                        Zobacz w czasie rzeczywistym, jak będzie wyglądać Twoja wiadomość.
                    </p>
                    <div className='mb-6 fs-8 font-heading text-white pt-md-64 mt-md-40'>
                        [{subject}]
                    </div>
                    <div className='mb-6 fs-10 text-white'>
                        <div dangerouslySetInnerHTML={createMarkup()} className='html-live-preview'/>
                    </div>
                </div>
            </div>
        </div>
        <ConfirmModal isOpen={openSendToAllModal} setOpen={setOpenSendToAllModal}
                      submitDisabled={isSubmitting}
                      children={<p className='mb-0 mt-4 text-danger fs-10 fw-medium'>Czy na pewno chcesz
                          kontynuować? <span className='text-decoration-underline fw-bold'>
                          Tej akcji nie można cofnąć.</span></p>}
                      title={`Zaraz wyślesz ${isEmailValid ? "bezpośredniego maila" : " maila do wszystkich osób z podanej listy mailingowej."}`}
                      subtitleWarn={`Wysyłasz maila do: ${isEmailValid ? email : getSelectedTypesArray().map(type => NewsletterTypes[type as keyof typeof NewsletterTypes]).join(", ") + (getSelectedTypesToExcludeArray().length > 0 ? (" z wykluczeniem " + getSelectedTypesToExcludeArray().map(type => NewsletterTypes[type as keyof typeof NewsletterTypes]).join(", ")) : "")}`}
                      confirmButtonAction={sendForm}/>
    </>
}