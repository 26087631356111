import React from "react";
import GuestFooter from "../components/guest/GuestFooter";
import {Outlet, useLocation, useRouteLoaderData} from "react-router-dom";
import ScrollToTop from "../util/ScrollToTop";
import {TokenPayload} from "../service/AuthService";
import TutorHeader from "../components/tutor/TutorHeader";
import GuestHeader from "../components/guest/GuestHeader";
import TutorFooter from "../components/tutor/TutorFooter";
import {useCookies} from "react-cookie";
import CookieBanner from "../components/common/CookieBanner";
import AdminHeader from "../components/admin/AdminHeader";
import Clarity from "@microsoft/clarity";

export default function RootLayout() {
    const [cookies, setCookie] = useCookies(["cookieConsent"]);

    const token = useRouteLoaderData('root') as TokenPayload;
    const location = useLocation();

    // Check if the current path starts with /t/
    const isOnWhiteboard = location.pathname.startsWith('/t/');

    const isOnAdminPanel = location.pathname.startsWith('/admin');

    function addCookieCookiesConsent(consent: boolean) {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        setCookie("cookieConsent", consent, { path: "/", expires: oneYearFromNow });
    }

    function giveCookieConsent() {
        addCookieCookiesConsent(true);
        loadGoogleAnalytics();
        Clarity.consent(true);
    }

    function rejectCookieConsent() {
        addCookieCookiesConsent(false);
    }

    function loadGoogleAnalytics() {
        try {
            // @ts-ignore
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted'
            });
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <ScrollToTop />
            {
                isOnAdminPanel && <AdminHeader/>
            }
            {
                !isOnAdminPanel && !isOnWhiteboard && (location.pathname !== '/' || token ? <TutorHeader /> : <GuestHeader />)
            }
            <Outlet />
            {
                !isOnWhiteboard && (token ? <TutorFooter /> : <GuestFooter />)
            }
            {(cookies.cookieConsent === undefined || cookies.cookieConsent === null) &&
                <CookieBanner giveCookieConsent={giveCookieConsent} rejectCookieConsent={rejectCookieConsent}/>}
        </>
    );
}