import {motion} from "framer-motion";
import React, {ReactNode} from "react";

interface AnimatedButtonProps {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    type?: "submit" | "button" | "reset";
    customScale?: number;
    bounceEffect?: boolean;
    style?: any
}

export default function AnimatedButton(props: AnimatedButtonProps) {
    const {
        children,
        className,
        disabled,
        onClick,
        type,
        customScale,
        bounceEffect,
        style
    } = props;

    return <motion.button
        whileHover={{scale: customScale ? customScale : 1.1}}
        transition={bounceEffect ? {type: 'spring', stiffness: 400} : undefined}
        className={`${className ? className : ''}`}
        disabled={disabled}
        onClick={onClick ? onClick : () => {
        }}
        type={type ? type : "submit"}
        style={style ? style : {}}
    >
        {children}
    </motion.button>
}