import {format, parseISO} from "date-fns";

export function getCurrentDate() {
    const date = new Date();
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

export function formatISODateString(dateString: string) {
    try {
        const date = parseISO(dateString);
        return format(date, 'dd.MM.yyyy');
    } catch (error) {
        return "";
    }
}

export function formatISODateTimeString(dateString: string) {
    try {
        const date = parseISO(dateString);
        return format(date, 'dd.MM.yyyy HH:mm');
    } catch (error) {
        return "";
    }
}