import {useFetcher, useLoaderData} from "react-router-dom";
import React, {useState} from "react";
import {useSubmitFormAction} from "../../hooks/useSubmitFormAction";
import {CancelNewsletterData} from "../../pages/common/CancelNewsletterPage";

export default function CancelNewsletter() {
    const {email, type} = useLoaderData() as CancelNewsletterData;
    const fetcher = useFetcher();
    const {isSubmitting} = useSubmitFormAction('/', fetcher);

    const [reason, setReason] = useState('');

    return <>
        <div className='mb-7 text-center mw-md mx-auto'>
            <div
                className='mb-0 d-flex align-items-center justify-content-center mx-auto rounded-3'
                style={{width: 100, height: 100}}
            >
                <img
                    className='img-fluid'
                    style={{height: 64}}
                    src='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/tnk-logo-512-512.png'
                    alt=''
                />
            </div>
            <h2 className='font-heading mb-4 fs-7 text-white'>
                Anulowanie subskrypcji
            </h2>
            <p className='mb-0 fs-9 fw-medium text-secondary-light'>
                Przykro nam, że nas opuszczasz. Napisz nam proszę, co moglibyśmy zrobić lepiej.
            </p>
        </div>
        <fetcher.Form
            method='post'
            action='/anuluj-newsletter'
            className='mw-md mx-auto'
        >
            <div className='mb-4 row g-6'>
                <div className='col-12'>
                    <div className='form-group'>
                        <input name='email' value={email} hidden readOnly/>
                        <input name='type' value={type} hidden readOnly/>
                        <textarea
                            className='form-control text-secondary-dark shadow'
                            id='signInInput3-1'
                            aria-describedby='emailHelp'
                            placeholder='Napisz nam, dlaczego odchodzisz'
                            name='reason'
                            rows={4}
                            value={reason}
                            onChange={(event) => setReason(event.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className='mb-6 row g-4'>
                <div className='col-12'>
                    <button
                        className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Trwa anulowanie...' : 'Anuluj subskrypcję'}
                    </button>
                </div>
            </div>
        </fetcher.Form>
    </>
}