import React from 'react';
import IndexMainHeadingWithPhoto from '../../components/guest/index/IndexMainHeadingWithPhoto';
import IndexPricing from '../../components/guest/index/IndexPricing';
import IndexCta from '../../components/guest/index/IndexCta';
import IndexFeatures from "../../components/guest/index/IndexFeatures";
import IndexFAQ from "../../components/guest/index/IndexFAQ";
import IndexContact from "../../components/guest/index/IndexContact";
import {useRouteLoaderData} from "react-router-dom";
import {TokenPayload} from "../../service/AuthService";
import TutorIndex from "../../components/tutor/TutorIndex";
import Meta from "../../components/common/Meta";
import IndexTestimonials from "../../components/guest/index/IndexTestimonials";

export default function IndexPage() {
    const token = useRouteLoaderData('root') as TokenPayload;

    return <>
        {
            !token && <>
                <Meta title='Tablica na Korki'
                      description='Jedyna tablica do korepetycji online, jakiej potrzebujesz. Zbudowana przez nauczycieli z myślą o nauczycielach.'/>
                <IndexMainHeadingWithPhoto/>
                <IndexFeatures/>
                <IndexPricing/>
                <IndexFAQ/>
                <IndexTestimonials/>
                <IndexCta/>
                <IndexContact/>
            </>
        }
        {
            token && <>
                <Meta title='Dashboard korepetytora'/>
                <TutorIndex/>
            </>
        }
    </>
}

