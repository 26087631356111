import {Helmet} from 'react-helmet-async';
import {useLocation} from "react-router-dom";

type MetaProps = {
    title: string;
    description?: string;
    imageUrl?: string;
    noIndex?: boolean;
};

export default function Meta({title, description, imageUrl, noIndex}: MetaProps) {
    const location = useLocation();

    const descriptionOrDefault = description ?? 'Jedyna tablica na korepetycje, jakiej potrzebujesz.'

    const imageOrDefault = imageUrl ?? 'https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/tnk-logo-og.png'

    const fullUrl = `${process.env.REACT_APP_FRONTEND_URL}${location.pathname}`;

    const getImageMimeType = (url: string): string | null => {
        const extension = url.split('.').pop()?.toLowerCase();
        const mimeTypes: { [key: string]: string } = {
            'png': 'png',
            'jpg': 'image/jpeg',
            'jpeg': 'image/jpeg',
            'gif': 'image/gif'
        };
        // @ts-ignore
        return mimeTypes[extension] || null;
    };

    const mimeType = getImageMimeType(imageOrDefault);

    return <Helmet>
        <title>{title}</title>
        <meta name="title" content={title}/>
        <meta name="description" content={descriptionOrDefault}/>
        <meta property="og:image" content={imageOrDefault}/>
        <meta property="og:image:url" content={imageOrDefault}/>
        {
            mimeType && <meta property="og:image:type" content={mimeType}/>
        }
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={descriptionOrDefault}/>
        <meta property="og:url" content={fullUrl}/>
        <meta property="og:type" content="website"/>
        <meta name="twitter:card" content={imageOrDefault}/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={descriptionOrDefault}/>
        <meta name="twitter:image" content={imageOrDefault}/>
        {
            noIndex && <meta name="robots" content="noindex"/>
        }
    </Helmet>
}
