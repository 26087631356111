import Header from "../common/header/Header";
import React from "react";

export default function GuestHeader() {
    return <>
        <Header topItems={[
            {
                title: 'Jak to działa?',
                to: '/#jak-to-dziala',
                hashLink: true
            },
            {
                title: 'Cennik',
                to: '/#cennik',
                hashLink: true
            },
            {
                title: 'Kontakt',
                to: '/#kontakt',
                hashLink: true
            }
        ]}/>
    </>
}