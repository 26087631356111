export default function RightArrow() {
    return <svg
        width={16}
        height={16}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M11.9467 7.74665C11.9149 7.66481 11.8674 7.59005 11.8067 7.52665L8.47333 4.19331C8.41117 4.13116 8.33738 4.08185 8.25617 4.04821C8.17495 4.01457 8.08791 3.99725 8 3.99725C7.82246 3.99725 7.6522 4.06778 7.52667 4.19331C7.46451 4.25547 7.4152 4.32927 7.38156 4.41048C7.34792 4.4917 7.33061 4.57874 7.33061 4.66665C7.33061 4.84418 7.40113 5.01445 7.52667 5.13998L9.72667 7.33331H4.66667C4.48986 7.33331 4.32029 7.40355 4.19526 7.52858C4.07024 7.6536 4 7.82317 4 7.99998C4 8.17679 4.07024 8.34636 4.19526 8.47139C4.32029 8.59641 4.48986 8.66665 4.66667 8.66665H9.72667L7.52667 10.86C7.46418 10.922 7.41458 10.9957 7.38074 11.0769C7.34689 11.1582 7.32947 11.2453 7.32947 11.3333C7.32947 11.4213 7.34689 11.5085 7.38074 11.5897C7.41458 11.6709 7.46418 11.7447 7.52667 11.8066C7.58864 11.8691 7.66238 11.9187 7.74361 11.9526C7.82485 11.9864 7.91199 12.0038 8 12.0038C8.08801 12.0038 8.17514 11.9864 8.25638 11.9526C8.33762 11.9187 8.41136 11.8691 8.47333 11.8066L11.8067 8.47331C11.8674 8.40991 11.9149 8.33515 11.9467 8.25331C12.0133 8.09101 12.0133 7.90895 11.9467 7.74665Z'
            fill='currentColor'
        />
    </svg>
}