import React from "react";

interface IndexFeatureProps {
    title: string
    subtitle: string
    iconUrl: string
    height?: string
}

export default function IndexFeature(props: IndexFeatureProps) {
    const { title, subtitle, iconUrl, height } = props
    return <>
        <div className="col-12 col-md-6 col-lg-4">
            <div className="mw-sm mx-auto text-center">
                <div
                    className="mb-6 d-flex justify-content-center align-items-center mx-auto text-white bg-success rounded"
                    style={{width: 64, height: 64}}>
                    <img style={{width: height ?? "32px", height: height?? "32px"}} src={iconUrl ?? ''} alt='Ikona'/>
                </div>
                <h3 className="font-heading fs-8 mb-4">{title}</h3>
                <p className="mb-0 fw-medium text-secondary-light">{subtitle}</p>
            </div>
        </div>
    </>
}