import RawModal from "../../ui/modal/RawModal";
import React from "react";
import {Board} from "../../../model/tutor/Board";
import AnimatedButton from "../../ui/motion/AnimatedButton";

interface BoardInfoModalProps {
    isOpen: boolean
    setOpen: (value: boolean) => void
    board: Board
    onEditInfoClick: () => void
    isTutor: boolean
}

export default function BoardInfoModal(props: BoardInfoModalProps) {
    const {isOpen, setOpen, board, onEditInfoClick, isTutor} = props;

    return (
        <>
            <RawModal
                isOpen={isOpen}
                setOpen={setOpen}
                title={board.title}
                children={
                    <>
                        <hr className='text-white'/>
                        <h3 className='text-white fw-medium text-secondary-light fs-9'>Opis zajęć:</h3>
                        <p className=" fw-medium text-secondary-light fs-11">
                            <i>
                                {
                                    (!board.description || board.description.length === 0) ? 'Korepetytor nie wprowadził jeszcze opisu zajęć.' :
                                        board.description.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                {index < board.description.split('\n').length - 1 && <br/>}
                                            </React.Fragment>
                                        ))
                                }
                            </i>
                        </p>
                        {
                            isTutor && <AnimatedButton className='btn btn-lg btn-success text-success-light shadow' type='button' onClick={onEditInfoClick}>
                                Edytuj szczegóły
                            </AnimatedButton>
                        }

                    </>
                }
            />
        </>
    );
}