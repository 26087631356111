import React from "react";
import MainDesign from "../../components/ui/MainDesign";
import Spinner from "../../components/common/Spinner";

interface LoadingPageProps {
    styles?: string
}

export default function LoadingPage(props: LoadingPageProps) {
    const {styles} = props;
    return <>
        <MainDesign containerStyles={`${styles ? styles : 'pt-40'}`}>
            <div className='mb-7 text-center mw-md mx-auto'>
                <div className="d-flex justify-content-center">
                    <Spinner/>
                </div>
            </div>
        </MainDesign>
    </>
}