import Header from "../common/header/Header";
import React from "react";

export default function TutorHeader() {
    return <>
        <div className='pt-lg-2 bg-secondary-dark'>
            <Header topItems={[
            ]}/>
        </div>

    </>
}