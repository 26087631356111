import React from 'react';
import {AuthService} from "../../../service/AuthService";
import MainDesign from "../../ui/MainDesign";
import AnimatedLink from "../../ui/motion/AnimatedLink";

export default function IndexMainHeadingWithPhoto() {
    return (
        <React.Fragment>
            <>
                <MainDesign containerStyles={'container py-28'}>
                        <div className="row g-16">
                            <div className="col-12 col-md-6">
                                <div className="mw-md-lg">
                                        <span
                                            className="mb-4 badge bg-success text-white text-uppercase shadow">TABLICANAKORKI.PL</span>
                                    <h1 className="mb-6 font-heading fs-5 text-white"
                                        style={{letterSpacing: '-1px'}}>Jedyna
                                        tablica do korepetycji, jakiej potrzebujesz!</h1>
                                    <p className="mb-8 fs-9 fw-medium text-secondary-light">Masz już dość szukania
                                        właściwej tablicy na korepetycje? Jesteś w idealnym miejscu.</p>
                                    <div className="row g-4">
                                        <div className="col-12 col-md-auto"><AnimatedLink
                                            className="btn btn-lg btn-success w-100 text-success-light shadow"
                                            to="/demo">Zobacz demo</AnimatedLink></div>
                                        <div className="col-12 col-md-auto">
                                                <AnimatedLink
                                                className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                                to="/rejestracja" onClick={AuthService.signOut}>Przetestuj już teraz
                                                </AnimatedLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="row justify-content-center">
                                    <div className="position-relative col-auto">
                                        <img className="position-absolute top-0 start-0 mt-n12 ms-n12"
                                             style={{zIndex: 1}} src="/flex-assets/elements/circle-orange.svg"
                                             alt={''}/>
                                        <img className="position-absolute bottom-0 end-0 me-n5 mb-n5"
                                             style={{zIndex: 1}} src="/flex-assets/elements/dots-blue.svg" alt={''}/>
                                        <div className="position-relative overflow-hidden rounded-5"
                                             style={{maxWidth: 554}}>
                                            <img className='lazyload img-fluid' data-src='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/elements/main-page-photo/main-tnk.png' alt={'Tablica na korepetycje'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </MainDesign>
            </>
        </React.Fragment>
    );
}

