interface AdvantageItemProps {
    title: string
    customStyles?: string
}

export default function AdvantageItem(props: AdvantageItemProps) {
    const {title, customStyles} = props;
    return <li className='mb-4 d-flex align-items-start'>
        <img
            style={{width: '26px', height: '26px'}}
            className='me-2'
            src='https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/checkbox-green.svg'
            alt='checkbox'
        />
        <p className={customStyles ? customStyles : 'mb-0 fs-10 fw-medium text-white'}>
            {title}
        </p>
    </li>
}