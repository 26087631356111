import {useAsyncError, useRouteError} from "react-router-dom";
import React from "react";
import MainDesign from "../ui/MainDesign";
import AnimatedLink from "../ui/motion/AnimatedLink";
import AnimatedButton from "../ui/motion/AnimatedButton";
import {ApiError} from "../../service/HttpService";

interface ErrorContentProps {
    messageProps?: string
    customTryAgainButton?: {
        title: string,
        link: string
    }
}

export default function ErrorContent(props: ErrorContentProps) {
    const {messageProps, customTryAgainButton} = props;
    const error = useRouteError() as ApiError;
    const asyncError = useAsyncError() as ApiError;

    let httpsStatus;
    if (error && error.httpsStatus) {
        httpsStatus = error.httpsStatus;
    } else if (asyncError && asyncError.httpsStatus) {
        httpsStatus = asyncError.httpsStatus;
    }

    let message = "Przepraszamy, ale coś poszło nie tak :(";
    if (error && error.message) {
        message = error.message;
    } else if (asyncError && asyncError.message) {
        message = asyncError.message;
    } else if (messageProps) {
        message = messageProps;
    }

    return <MainDesign containerStyles="container py-16">
        <div className='row align-items-center g-16'>
            <div className='col-12 col-md-6'>
                <div className='d-flex justify-content-center'>
                    <img
                        className='img-fluid'
                        src='https://produkacja.s3.eu-central-1.amazonaws.com/web/common/error-code.png'
                        alt=''
                    />
                </div>
            </div>
            <div className='col-12 col-md-6'>
                <div className='mw-md-5xl mx-auto'>
                <span className='badge mb-4 bg-success-dark text-success shadow'>
                  Error
                </span>
                    <h1
                        className='font-heading mb-4 fs-5 text-white'
                        style={{letterSpacing: '-1px'}}
                    >
                        O nie! {httpsStatus ? `Błąd ${httpsStatus}` : 'Wystąpił błąd'}
                    </h1>
                    <p className='mb-12 fs-9 text-secondary-light'>
                        {message}
                    </p>
                    <div className='row g-4 g-md-6'>
                        <div className='col-12 col-md-auto'>
                            <AnimatedLink
                                className='btn btn-lg btn-success w-100 text-success-light shadow'
                                to='/'
                            >
                                Wróć do strony głównej
                            </AnimatedLink>
                        </div>
                        <div className='col-12 col-md-auto'>
                            {
                                customTryAgainButton ? <AnimatedLink
                                    className='btn btn-lg btn-dark-light w-100 text-success-light shadow'
                                    to={customTryAgainButton.link}
                                >
                                    {customTryAgainButton.title}
                                </AnimatedLink> :  <AnimatedButton
                                    className='btn btn-lg btn-dark-light w-100 text-success-light shadow'
                                    onClick={() => window.location.reload()}
                                >
                                    Spróbuj ponownie
                                </AnimatedButton>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainDesign>
}