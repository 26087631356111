import {RefObject, useEffect, useRef, useState} from "react";

export function useHandleDirtyForm(initialIsDirty: boolean = false) {
    function handleDirtyForm(isFormDirtyRef: RefObject<boolean>) {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isFormDirtyRef.current) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        // Attach event listener if the form is dirty
        if (isFormDirtyRef.current) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        // Return a cleanup function
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }


    const [isFormDirty, setIsFormDirty] = useState<boolean>(initialIsDirty);
    const isFormDirtyRef = useRef<boolean>(isFormDirty);

    useEffect(() => {
        // Update the ref every time the state changes
        isFormDirtyRef.current = isFormDirty;

        // Call handleDirtyForm and return the cleanup function
        return handleDirtyForm(isFormDirtyRef);
    }, [isFormDirty]);

    return [isFormDirty, setIsFormDirty] as const;
}