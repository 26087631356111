import {HttpMethod, HttpService} from "./HttpService";
import {
    Code,
    CreateCodeRequest,
    RedeemPromoCodeRequest,
    RedeemPromoCodeResponse, Referral,
    ToggleCodeActiveRequest
} from "../model/Code";

export class CodeService {
    public static async createCode(createCodeRequest: CreateCodeRequest): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/code/create',
            JSON.stringify(createCodeRequest)
        );
    }

    public static async toggleCodeActive(toggleCodeActiveRequest: ToggleCodeActiveRequest): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/code/toggleActive',
            JSON.stringify(toggleCodeActiveRequest)
        );
    }

    public static async getCodes(): Promise<Code[]> {
        return HttpService.sendRequest<Code[]>(
            HttpMethod.GET,
            '/code',
        );
    }

    public static async getUserReferrals(): Promise<Referral[]> {
        return HttpService.sendRequest<Referral[]>(
            HttpMethod.GET,
            '/code/referrals',
        );
    }

    public static async createReferralCode(): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/code/createReferralCode'
        );
    }

    public static async redeemPromoCode(redeemPromoCodeRequest: RedeemPromoCodeRequest): Promise<RedeemPromoCodeResponse> {
        return HttpService.sendRequest<RedeemPromoCodeResponse>(
            HttpMethod.POST,
            '/code',
            JSON.stringify(redeemPromoCodeRequest)
        );
    }
}