import React, {useEffect} from 'react';

interface ToastProps {
    show: boolean;
    setShow: (show: boolean) => void;
    message: string;
}

export default function Toast(props: ToastProps) {
    const {show, setShow, message} = props;

    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => setShow(false), 4000);
            return () => clearTimeout(timer);
        }
    }, [show, setShow]);

    return (
        <div className={`toast my-toast align-items-center1 ${show ? 'show' : ''}`}
             role="alert"
             aria-live="assertive" aria-atomic="true">
            <div className="d-flex">
                <div className="toast-body fs-9">
                    {message}
                </div>
                <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                        aria-label="Close" onClick={() => setShow(false)}></button>
            </div>
        </div>
    );
};
