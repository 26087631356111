import Meta from "../../components/common/Meta";
import React from "react";
import MainDesign from "../../components/ui/MainDesign";
import AdminNewsletter from "../../components/admin/newsletter/AdminNewsletter";
import {ApiError} from "../../service/HttpService";
import {AdminSendNewsletterRequest, NewsletterTypes} from "../../model/admin/Newsletter";
import {AdminService} from "../../service/AdminService";

export default function AdminNewsletterPage() {
    return <>
        <Meta title='Newsletter'/>
        <MainDesign containerStyles='py-20 container'>
            <AdminNewsletter/>
        </MainDesign>
    </>
}


export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    try {
        const response = await sendNewsletter(formData);

        return {
            status: 200,
            body: response.message
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}

async function sendNewsletter(formData: FormData) {
    const rawTypes = formData.get('types') as string;
    const rawTypesToExclude = formData.get('typesToExclude') as string;
    const types = rawTypes.split(",") as NewsletterTypes[];
    const typesToExclude = rawTypesToExclude.split(",") as NewsletterTypes[];
    const sendNewsletterData = {
        subject: formData.get('subject') as string,
        message: formData.get('message') as string,
        email: formData.get('email') as string,
        joinedBefore: formData.get('joinedBefore') as string,
        source: formData.get('source') as string,
        types: types,
        typesToExclude: typesToExclude.length === 1 && typesToExclude[0] as any === "" ? [] : typesToExclude
    } as AdminSendNewsletterRequest;

    return await AdminService.sendNewsletter(sendNewsletterData);
}
