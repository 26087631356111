import {User} from "../../../model/tutor/User";
import {Referral} from "../../../model/Code";
import NumbersItem from "../../ui/element/item/NumbersItem";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import CopyToClipIcon from "../../ui/element/CopyToClipIcon";
import React from "react";
import {useToast} from "../../ui/toast/ToastContext";
import {CodeService} from "../../../service/CodeService";
import {useNavigate} from "react-router-dom";

interface ReferralDashboardProps {
    user: User
    referrals: Referral[]
}

export default function ReferralDashboard({user, referrals}: ReferralDashboardProps) {
    const {showMessage} = useToast();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [promoCodeDetails, setPromoCodeDetails] = React.useState(false);

    const hasReferralCode = !!user.referralCode

    async function copyToClip() {
        await navigator.clipboard.writeText(user.referralCode ?? '');
        showMessage("Pomyślnie skopiowano.");
    }

    async function generateReferralCode() {
        setIsSubmitting(true);
        try {
            await CodeService.createReferralCode();
            navigate(".", {replace: true});
        } catch (error: any) {
            console.error('Error generating referral code', error);
            showMessage(error.message || 'Wystąpił błąd podczas generowania kodu. Spróbuj ponownie.');
        } finally {
            setIsSubmitting(false);
        }
    }

    return <>
        <div className='row g-16 mb-20 mw-md-xl mx-auto'>
            <div
                className={`row justify-content-center g-2 mt-12 ${hasReferralCode ? 'mw-md-xs' : 'mw-md-sm'}  mx-auto`}>
                {
                    hasReferralCode ? <>
                        <p className="mb-0 fs-10 text-center fw-medium text-secondary-light">Twój kod polecający to:</p>
                        <input
                            type="text"
                            className="form-control form-control-lg flex-grow-1 bg-dark"
                            value={user.referralCode}
                            readOnly
                            style={{
                                padding: '1rem 0.75rem',
                                color: "white",
                                textAlign: "center"
                            }} // Custom padding for smaller input
                        />
                        <AnimatedButton
                            className="btn btn-outline-light btn-sm flex-grow-0"
                            type="button"
                            onClick={copyToClip}
                            style={{whiteSpace: 'nowrap'}} // Ensure text stays in one line
                        >
                            <CopyToClipIcon/>
                            <span>Skopiuj swój kod</span>
                        </AnimatedButton>
                    </> : <>
                        <p className="mb-0 fs-10 text-center fw-medium text-secondary-light">Aby
                            wygenerować swój unikalny kod polecający, kliknij w poniższy przycisk.</p>
                        <AnimatedButton disabled={isSubmitting} onClick={generateReferralCode}
                                        className='btn btn-lg btn-success text-success-light shadow'>Wygeneruj
                            kod</AnimatedButton>
                    </>
                }

            </div>
            <NumbersItem metricValue={referrals.length} metricTitle='Polecenia'/>
            <NumbersItem metricValue={`${(referrals.length * 29.99).toFixed(2)}zł`} metricTitle='Zaoszczędzono'/>
            <p className="mb-0 mt-6 fs-12 text-center fw-medium text-white">Jeżeli ktoś wykupi miesięczną subskrypcję
                korzystając z Twojego kodu, oboje otrzymacie dodatkowe <b>31 dni subskrypcji</b> całkowicie za darmo.
            </p>
            <p className='text-success fw-medium fs-12 text-center mt-4 text-decoration-underline'
               style={{cursor: 'pointer', userSelect: 'none'}} onClick={() => {
                if (!isSubmitting) {
                    setPromoCodeDetails((prev) => !prev)
                }
            }}>
                Jak działają kody polecające?
            </p>
            {
                promoCodeDetails &&
                <div className='row justify-content-center g-2 mt-0 mw-md-lg mx-auto'>
                    <p className="mb-0 fs-12 fw-medium text-secondary-light">
                        1. Każdy użytkownik może użyć kodu polecającego innego użytkownika tylko raz.
                    </p>
                    <p className="mb-0 fs-12 fw-medium text-secondary-light">
                        2. Z jednego kodu może skorzystać nielimitowana ilość osób. Na przykład, jeżeli polecisz
                        aplikację 10 osobom i każda z nich wykupi subskrypcję, otrzymasz 10 miesięcy subskrypcji
                        całkowicie za darmo.
                    </p>
                    <p className="mb-0 fs-12 fw-medium text-secondary-light">
                        3. Każda polecona osoba otrzymuje dwa miesiące subskrypcji w cenie jednego.
                    </p>
                    <p className="mb-0 fs-12 fw-medium text-secondary-light">
                        4. Polecenie jest liczone w momencie wykupienia subskrypcji przez innego użytkownika.
                    </p>
                    <p className="mb-0 fs-12 fw-medium text-secondary-light">
                        5. Nie ma ograniczenia czasowego na wykorzystanie kodu polecającego innego użytkownika.
                    </p>
                    <p className="mb-0 fs-12 fw-medium text-secondary-light">
                        6. Twój kod polecający jest unikalny i nie można go zmienić.
                    </p>
                    <p className="mb-0 fs-12 fw-medium text-secondary-light">
                        7. Nie możesz wykorzystać własnego kodu.
                    </p>
                </div>
            }
        </div>

    </>
}