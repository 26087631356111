import React from "react";
import Footer from "../common/footer/Footer";

export default function GuestFooter() {
    return <Footer isGuest={true} links={
        [
            {
                name: 'Polityka prywatności',
                to: '/polityka-prywatnosci'
            },
            {
                name: 'Regulamin',
                to: '/regulamin'
            }
        ]
    }/>
}