import {HttpMethod, HttpService} from "./HttpService";
import {MetricsResponse} from "../model/admin/Metrics";
import {AdminNewsletterResponse, AdminSendNewsletterRequest} from "../model/admin/Newsletter";
import {CreateTemporaryUserRequest, CreateTempUserResponse} from "../model/admin/Offer";

export class AdminService {
    public static async getMetrics(): Promise<MetricsResponse> {
        return HttpService.sendRequest<MetricsResponse>(
            HttpMethod.GET,
            `/admin/metrics`,
        );
    }

    public static sendNewsletter(requestBody: AdminSendNewsletterRequest): Promise<AdminNewsletterResponse> {
        return HttpService.sendRequest<AdminNewsletterResponse>(
            HttpMethod.POST,
            '/admin/newsletter/send',
            JSON.stringify(requestBody)
        );
    }

    public static createTempUser(tempUserRequest: CreateTemporaryUserRequest): Promise<CreateTempUserResponse> {
        return HttpService.sendRequest<CreateTempUserResponse>(
            HttpMethod.POST,
            '/admin/tempUser',
            JSON.stringify(tempUserRequest)
        );
    }
}