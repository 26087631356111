import Meta from "../../../components/common/Meta";
import React, {Suspense} from "react";
import MainDesign from "../../../components/ui/MainDesign";
import AdminGenerateCodes from "../../../components/admin/offer/AdminGenerateCodes";
import AdminListCodes from "../../../components/admin/offer/AdminListCodes";
import {CodeService} from "../../../service/CodeService";
import {Code, CreateCodeRequest} from "../../../model/Code";
import {ApiError} from "../../../service/HttpService";
import {Await, defer, useLoaderData} from "react-router-dom";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/ErrorContent";

type LoaderData = {
    codes: Promise<Code[]>
}

export default function AdminCodesPage() {
    const {codes} = useLoaderData() as LoaderData;

    return <>
        <Meta title='Kody'/>
        <MainDesign containerStyles='py-20 container'>
            <AdminGenerateCodes/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={codes} errorElement={<ErrorContent/>}>
                    {(loadedCodes: Code[]) => <>
                        <AdminListCodes codes={loadedCodes}/>
                    </>}
                </Await>
            </Suspense>

        </MainDesign>
    </>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    try {
        const requestType = formData.get('requestType') as string;

        if (requestType === 'CREATE') {
            const expirationDate = formData.get('expirationDate') as string
            const maxUsages = formData.get('maxUsages') as string
            const subscriptionDays = formData.get('subscriptionDays') as string
            const discountedPrice = formData.get('discountedPrice') as string

            const createCodeRequest = {
                code: formData.get('code') as string,
                codeType: formData.get('codeType') as string,
                maxUsages: maxUsages ? Number(maxUsages) : undefined,
                expirationDate: !expirationDate || expirationDate.trim() === "" ? undefined : expirationDate,
                comment: formData.get('comment') as string,
                subscriptionDays: subscriptionDays ? Number(subscriptionDays) : undefined,
                discountedPrice: discountedPrice ? Number(discountedPrice) : undefined,
            } as CreateCodeRequest;

            await CodeService.createCode(createCodeRequest);

            return null;
        } else if (requestType === "TOGGLE_ACTIVE") {
            const code = formData.get('code') as string;
            await CodeService.toggleCodeActive({ code });

            return null;
        } else {
            throw new Error("Wprowadź poprawny typ.");
        }

    } catch (error) {
        const apiError = error as ApiError;
        console.error(apiError);
        return {
            status: apiError.httpsStatus || 500,
            body: apiError.message || "Wystąpił błąd."
        };
    }
}

async function loadCodes(): Promise<Code[]> {
    return await CodeService.getCodes();
}

export function loader() {
    return defer({
        codes: loadCodes()
    });
}