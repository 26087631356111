import React from "react";

interface HeadingProps {
    badgeText?: string;
    title: string;
    subtitle?: string;
    customStyles?: string;
    children?: string
    customTitleStyles?: string
    customSubtitleStyles?: string
}

export default function Heading(props: HeadingProps) {
    const {
        badgeText, title, subtitle, customStyles,
        children, customTitleStyles, customSubtitleStyles
    } = props;
    return <div className={customStyles ? customStyles : 'mb-6 text-center mw-md-4xl mx-auto'}>
        {
            badgeText && <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                {badgeText}
            </span>
        }

        <h1
            className={`${customTitleStyles ? customTitleStyles : 'font-heading mb-4 fs-5 text-white'}`}
            style={{letterSpacing: '-1px'}}
        >
            {title}
        </h1>
        {
            subtitle &&
            <p className={`${customSubtitleStyles ? customSubtitleStyles : 'fs-10 fw-medium text-secondary-light'}`}>
                {subtitle}
            </p>
        }
        {
            children && children
        }
    </div>
}