import React, {ReactNode} from "react";
import {Form} from "react-router-dom";
import AnimatedButton from "../motion/AnimatedButton";

interface ModalProps {
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    title: string;
    subtitle?: string;
    subtitleWarn?: string;
    confirmButtonAction?: () => void;
    isSubmitting?: boolean;
    children?: ReactNode;
    submitDisabled?: boolean;
    buttonType?: "submit" | "button" | "reset";
    customTextTop?: ReactNode;
    customTextBottom?: ReactNode;
    customSubtitleStyles?: string;
    customTitleStyles?: string;
}

export default function ConfirmModal(props: ModalProps) {
    const {
        isOpen,
        setOpen,
        title,
        subtitle,
        subtitleWarn,
        confirmButtonAction,
        isSubmitting,
        children,
        submitDisabled,
        buttonType,
        customTextTop,
        customTextBottom,
        customSubtitleStyles,
        customTitleStyles
    } = props

    return <section
        className={`position-fixed bottom-0 start-0 bg-secondary-dark bg-opacity-50 h-100 w-100 overflow-auto ${isOpen ? '' : 'd-none'}`}
        style={{zIndex: 50}}
    >
        <div className='d-flex flex-wrap align-items-center h-100'>
            <div className='container py-20'>
                <Form method='POST' className='p-10 mw-3xl mx-auto bg-light-dark overflow-hidden rounded shadow'>
                    <div className='mb-8 row g-8 align-items-center'>
                        <div className='col-auto'>
                            <div className='navbar-close text-secondary-light' style={{cursor: 'pointer'}}
                                 onClick={() => setOpen(false)}>
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M6 18L18 6M6 6L18 18'
                                        stroke='currentColor'
                                        strokeWidth={2}
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className={`col-11 mb-0 ${customTitleStyles ? customTitleStyles : ''}`}>
                            <span className='fs-7 fw-bold text-white'>{title}</span>
                        </div>
                        {
                            customTextTop && customTextTop
                        }
                        {
                            subtitle && <div className={customSubtitleStyles ? customSubtitleStyles : `col-11 mt-3`}>
                                <span className='fs-9 text-secondary-light'>{subtitle}</span>
                            </div>
                        }
                        {
                            subtitleWarn && <div className='col-11 mt-3'>
                                <span className='fs-9 text-warning fw-bold'>{subtitleWarn}</span>
                            </div>
                        }
                        {
                            customTextBottom && customTextBottom
                        }
                        {
                            children && children
                        }

                    </div>
                    <div className='row mt-8'>
                        <div className='col-12 col-md-4'>
                            <AnimatedButton
                                className="btn btn-lg btn-dark-light w-100 text-success-light shadow mt-2"
                                type="button"
                                onClick={() => setOpen(false)}
                            >
                                Anuluj
                            </AnimatedButton>
                        </div>
                        <div className='col-12 col-md-8'>
                            <AnimatedButton
                                className='btn btn-lg btn-success w-100 text-success-light shadow mt-2'
                                type={buttonType ?? "button"}
                                onClick={confirmButtonAction}
                                disabled={(isSubmitting ?? false) || (submitDisabled ?? false)}
                            >
                                Potwierdź
                            </AnimatedButton>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </section>
}