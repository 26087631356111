import {motion} from "framer-motion";
import React, {useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom";

interface TooltipWithHoverItemProps {
    text: string;
    customBgHex?: string;
}

export default function TooltipWithHoverItem(props: TooltipWithHoverItemProps) {
    const {text, customBgHex} = props;
    const [isHovered, setIsHovered] = useState(false);
    const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({});
    const triggerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isHovered && triggerRef.current) {
            const triggerRect = triggerRef.current.getBoundingClientRect();
            const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

            // Calculate initial tooltip position
            let left = triggerRect.right + 8; // 8px gap to the right of the icon
            let top = triggerRect.top + triggerRect.height / 2;

            const tooltipWidth = 200; // Fixed tooltip width
            const tooltipHeight = 50; // Approximate tooltip height for positioning

            // Adjust for viewport overflow
            if (left + tooltipWidth > viewportWidth) {
                left = triggerRect.left - tooltipWidth - 8; // Move to the left of the icon
            }

            if (top + tooltipHeight / 2 > window.innerHeight) {
                top = window.innerHeight - tooltipHeight - 10; // Prevent bottom overflow
            } else if (top - tooltipHeight / 2 < 0) {
                top = tooltipHeight / 2 + 10; // Prevent top overflow
            }

            setTooltipStyle({
                position: "fixed",
                top: `${top}px`,
                left: `${left}px`,
                transform: "translateY(-50%)", // Vertically center relative to the icon
                zIndex: 9999,
                width: `${tooltipWidth}px`,
                maxWidth: "90vw", // Prevent overflow on small screens
                padding: "8px", // Ensure enough padding
                fontSize: "14px", // Standard font size for readability
                lineHeight: "1.5", // Ensure proper line height
                textAlign: "center", // Center-align the text
                color: "white", // Keep text color readable
                backgroundColor: customBgHex ?? "#333F51", // Background with custom or default color
                borderRadius: "8px", // Rounded corners
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
                overflowWrap: "break-word", // Handle long words gracefully
            });
        }
    }, [isHovered]);

    return (
        <>
            <motion.div
                ref={triggerRef}
                style={{
                    display: "inline-block",
                    position: "relative",
                    marginLeft: "5px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    backgroundColor: customBgHex ?? "#333F51",
                    color: "white",
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                    lineHeight: "18px",
                    textAlign: "center",
                    cursor: "pointer",
                }}
                whileHover={{scale: 1.1}}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                ?
            </motion.div>
            {isHovered &&
                ReactDOM.createPortal(
                    <motion.div
                        style={tooltipStyle}
                        initial={{opacity: 0, x: 10}}
                        animate={{opacity: 1, x: 0}}
                        exit={{opacity: 0, x: 10}}
                        transition={{duration: 0.3}}
                    >
                        {text}
                    </motion.div>,
                    document.body
                )}
        </>
    );
}