export enum NewsletterTypes {
    TEST_EMAIL = "TEST_EMAIL",
    ALL_TUTORS = "ALL_TUTORS",
    ALL_TUTORS_MARKETING = "ALL_TUTORS_MARKETING",
    ALL_TUTORS_SUBSCRIBERS = "ALL_TUTORS_SUBSCRIBERS",
    ALL_TUTORS_SUBSCRIBERS_MARKETING = "ALL_TUTORS_SUBSCRIBERS_MARKETING"
}

export interface AdminSendNewsletterRequest {
    subject: string
    message: string
    email?: string
    source?: string
    types: NewsletterTypes[]
    typesToExclude: NewsletterTypes[]
}

export interface AdminNewsletterResponse {
    message: string
}