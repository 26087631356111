import React, {useEffect, useState} from 'react';
import MainDesign from "../../ui/MainDesign";
import {motion, AnimatePresence} from 'framer-motion';
import {Link} from "react-router-dom";

export default function IndexTestimonials() {
    const testimonials = [
        {
            quote: "TablicaNaKorki.PL to strzał w dziesiątkę! Bez opóźnień, łatwe udostępnianie i pełna kontrola nad materiałami. Moi uczniowie są zachwyceni, a ja mogę skupić się na nauczaniu.",
            name: "Martyna",
            role: "Nauczycielka Chemii",
            imageUrl: "https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/testimonials/martyna.png"
        },
        {
            quote: "Przetestowałem wiele tablic online, ale żadna nie spełniała moich oczekiwań tak jak TablicaNaKorki.PL. Elastyczność i funkcjonalność tej platformy znacznie ułatwiły mi prowadzenie zajęć.",
            name: "Adam",
            role: "Korepetytor Matematyki",
            imageUrl: "https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/testimonials/adam.png"
        },
        {
            quote: "Tablica używana na zajęciach jest najlepsza spośród wszystkich, z których korzystałam podczas korepetycji online. Wszystko jest przejrzyste, a praca na niej jest wyjątkowo wygodna.",
            name: "Kasia",
            role: "Uczennica II klasy Liceum",
            imageUrl: "https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/testimonials/kasia.png"
        },
        {
            quote: "Pierwsza tablica, która umożliwia mi wygodne wracanie do materiału sprzed kilku miesięcy. Porządek i prostota.",
            name: "Michał",
            role: "Uczeń III klasy Technikum",
            imageUrl: "https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/testimonials/micha%C5%82.png"
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [lastInteraction, setLastInteraction] = useState(Date.now());

    // Automatically switch testimonials every 5 seconds, reset on interaction
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 8000);
        return () => clearInterval(interval);
    }, [testimonials.length, lastInteraction]);

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
        setLastInteraction(Date.now());
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex + 1) % testimonials.length
        );
        setLastInteraction(Date.now());
    };

    const handleDotClick = (index: number) => {
        setCurrentIndex(index);
        setLastInteraction(Date.now());
    };

    const {quote, name, role, imageUrl} = testimonials[currentIndex];

    const transitionVariants = {
        initial: {opacity: 0, x: 20},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: -20}
    };

    return (
        <MainDesign containerStyles='container'>
            <div
                className="text-center position-relative"
                style={{padding: '100px 0', overflow: 'visible'}}
            >
                <span
                    className="badge mb-12 bg-success-dark text-success text-uppercase shadow"
                    style={{zIndex: 2, position: 'relative'}}
                >
                    Co mówią inni?
                </span>
                <div
                    className="d-flex justify-content-center align-items-center mb-16"
                    style={{width: '100%', overflow: 'visible'}}
                >
                    {/* Previous Button */}
                    <button
                        onClick={handlePrevious}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '3rem',
                            color: '#fff',
                            marginRight: '1rem',
                            zIndex: 2,
                            position: 'relative'
                        }}
                        aria-label="Previous Testimonial"
                    >
                        &#8249;
                    </button>

                    {/* Testimonial Animation Wrapper */}
                    <div
                        className="position-relative mx-4"
                        style={{overflow: 'visible'}}
                    >
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentIndex}
                                variants={transitionVariants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                transition={{duration: 0.5}}
                                style={{position: 'relative', overflow: 'visible'}}
                            >
                                <h2
                                    className="position-relative mb-0 font-heading fs-7 fw-semibold px-md-32 py-16 py-md-0"
                                    style={{
                                        zIndex: 2,
                                        letterSpacing: '-1px',
                                        overflow: 'visible',
                                    }}
                                >
                                    {quote}
                                    <img
                                        className="position-absolute top-0 start-0 mt-n6 ms-n7"
                                        src="/flex-assets/elements/quote-top-green.svg"
                                        alt=""
                                        style={{zIndex: 1, pointerEvents: 'none'}}
                                    />
                                    <img
                                        className="position-absolute bottom-0 end-0 mb-n6 me-n7"
                                        src="/flex-assets/elements/quote-down-green.svg"
                                        alt=""
                                        style={{zIndex: 1, pointerEvents: 'none'}}
                                    />
                                </h2>
                            </motion.div>
                        </AnimatePresence>
                    </div>

                    {/* Next Button */}
                    <button
                        onClick={handleNext}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '3rem',
                            color: '#fff',
                            marginLeft: '1rem',
                            zIndex: 2,
                            position: 'relative'
                        }}
                        aria-label="Next Testimonial"
                    >
                        &#8250;
                    </button>
                </div>

                {/* Image if present */}
                {imageUrl && (
                    <div style={{zIndex: 2, position: 'relative', margin: '40px auto'}}>
                        <img
                            src={imageUrl}
                            alt={name}
                            style={{maxWidth: '100px', borderRadius: '50%'}}
                        />
                    </div>
                )}

                <h3 className="mb-2 fs-8 fw-semibold text-light-dark text-white"
                    style={{zIndex: 2, position: 'relative'}}>{name}</h3>
                <p className="mb-8 fs-10 text-secondary-light fw-medium"
                   style={{zIndex: 2, position: 'relative'}}>{role}</p>

                {/* Indicator Dots */}
                <div className="d-inline-flex" style={{zIndex: 2, position: 'relative'}}>
                    {testimonials.map((_, i) => (
                        <Link
                            key={i}
                            to=""
                            onClick={(e) => {
                                e.preventDefault();
                                handleDotClick(i);
                            }}
                            className={i === currentIndex ? "mx-1 bg-success rounded-pill" : "mx-1 bg-light rounded-pill"}
                            style={{width: 12, height: 12, display: 'inline-block'}}
                        />
                    ))}
                </div>
            </div>
        </MainDesign>
    );
}