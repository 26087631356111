import Meta from "../../../components/common/Meta";
import React from "react";
import MainDesign from "../../../components/ui/MainDesign";
import {ApiError} from "../../../service/HttpService";
import {AdminService} from "../../../service/AdminService";
import {CreateTemporaryUserRequest} from "../../../model/admin/Offer";
import AdminOffer from "../../../components/admin/offer/AdminOffer";

export default function AdminOfferPage() {
    return <>
        <Meta title='Oferta'/>
        <MainDesign containerStyles='py-20 container'>
            <AdminOffer/>
        </MainDesign>
    </>
}


export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    const email = formData.get('email') as string;

    const requestBody = {
        name: formData.get('name') as string,
        email: !email || email.trim() === "0" ? undefined : email,
        subscriptionDays: Number(formData.get('subscriptionDays')) as unknown as number,
        comment: formData.get('comment') as string
    } as CreateTemporaryUserRequest;

    try {
        const responseData = await AdminService.createTempUser(requestBody);

        return {
            status: 200,
            body: JSON.stringify(responseData)
        };
    } catch (error) {
        console.log(error);
        const apiError = error as ApiError;
        console.error(apiError);
        return {
            status: apiError.httpsStatus || 500,
            body: apiError.message || "Wystąpił błąd."
        };
    }
}