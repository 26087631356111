import AnimatedLink from "../motion/AnimatedLink";
import React from "react";

interface SmallSquareLogoProps {
    width?: string
    disableLink?: boolean
}

export default function SmallSquareLogo(props: SmallSquareLogoProps) {
    const {width, disableLink} = props;
    return <div className="d-flex justify-content-center mb-2">
        {
            disableLink ? <img className="img-fluid" style={{width: width ?? '48px'}}
                                src="https://produkacja.s3.eu-central-1.amazonaws.com/tnk-web/logo/tnk-logo-1.png"
                                alt='logo'/> :<AnimatedLink to="/">
                <img className="img-fluid" style={{width: width ?? '48px'}}
                     src="https://produkacja.s3.eu-central-1.amazonaws.com/tnk-web/logo/tnk-logo-1.png" alt=''/>
            </AnimatedLink>
        }

    </div>
}