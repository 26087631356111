import {useSubmit} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import FormModal from "../../ui/modal/FormModal";
import FormInput from "../../ui/form/FormInput";
import FormTextArea from "../../ui/form/FormTextArea";
import {Board} from "../../../model/tutor/Board";
import {getCurrentDate} from "../../../util/DateUtil";

interface AddOrEditBoardModalProps {
    isOpen: boolean
    setOpen: (value: boolean) => void
    board?: Board
    setBoardToEdit?: React.Dispatch<React.SetStateAction<Board | null>>
    studentId: string
    customTitle?: string
    redirectAfterEditUrl?: string
}

export default function AddOrEditBoardModal(props: AddOrEditBoardModalProps) {
    const {isOpen, setOpen, board, setBoardToEdit, studentId, customTitle, redirectAfterEditUrl} = props

    const submit = useSubmit();
    const {isSubmitting} = useSubmitFormAction(redirectAfterEditUrl ?? '/', null, () => {
        setOpen(false);
        setDescription("");
        if (setBoardToEdit) {
            setBoardToEdit(null);
        }
    });

    const [title, setTitle] = useState(board?.title ?? `Tablica z dnia ${getCurrentDate()}`);
    const [description, setDescription] = useState(board?.description ?? "");

    useEffect(() => {
        setTitle(board?.title ?? `Tablica z dnia ${getCurrentDate()}`);
        setDescription(board?.description ?? "");
    }, [board])

    const isEditing = !!board;

    function submitForm() {
        submit({
            id: board?.id ?? "",
            title: title,
            description: description,
            requestType: isEditing ? "EDIT" : "CREATE",
            studentId: studentId
        }, {
            method: "POST",
        });
    }

    return <FormModal isOpen={isOpen} setOpen={setOpen}
                      title={customTitle? customTitle : (board ? `Edytujesz tablicę '${board.title}'` : 'Dodajesz nową tablicę')}
                      form={
                          <>
                              <div className='col-12'>
                                  <FormInput label='Nazwa tablicy:' placeholder='Podaj nazwę...' value={title}
                                             onChange={setTitle}/>
                              </div>
                              <div className='col-12'>
                                  <FormTextArea label={`Opis zajęć:${board ? '' : ' (możesz uzupełnić go później)'}`} placeholder='Podaj opis...'
                                                value={description} onChange={setDescription} rows={4}/>
                              </div>
                          </>
                      }
                      isSubmitting={isSubmitting}
                      onSubmit={submitForm}
                      onClose={() => {
                          if (setBoardToEdit) {
                              setBoardToEdit(null);
                          }
                      }}
    />
}