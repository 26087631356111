import React, {createContext, ReactNode, useCallback, useContext, useState} from 'react';
import Toast from "./Toast";

interface ToastContextData {
    showMessage: (message: string) => void;
}

interface ToastProviderProps {
    children: ReactNode;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

export const useToast = () => useContext(ToastContext);

export function ToastProvider(props: ToastProviderProps) {
    const {children} = props;

    const [message, setMessage] = useState('');
    const [show, setShow] = useState(false);

    const showMessage = useCallback((message: string) => {
        setMessage(message);
        setShow(true);
        setTimeout(() => setShow(false), 4000);
    }, []);

    return (
        <ToastContext.Provider value={{showMessage}}>
            {children}
            {show && <Toast message={message} show={show} setShow={setShow}/>}
        </ToastContext.Provider>
    );
}
