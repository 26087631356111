import React from 'react';
import MainDesign from "../../ui/MainDesign";
import IndexFeature from "./IndexFeature";

export default function IndexFeatures() {
    return (
        <React.Fragment>
            <>
                <MainDesign id='jak-to-dziala' containerStyles={'container'}>
                        <div className="mb-20 mw-4xl mx-auto text-center">
                            <span className="badge mb-4 bg-success-dark text-success text-uppercase shadow">JAK TO DZIAŁA?</span>
                            <h2 className="font-heading mb-4 fs-6 text-white" style={{letterSpacing: '-1px'}}>Zajęcia na najwyższej jakości.</h2>
                            <p className="mb-0 fs-9 fw-medium text-secondary-light">Wszystko czego potrzebujesz, aby uczeń mógł wynieść z zajęć jak najwięcej. Narzędzia dostosowane z myślą o korepetytorach.</p>
                        </div>
                        <div className="row g-16">
                            <IndexFeature title='Synchronizacja w czasie rzeczywistym.' subtitle='Tablice są synchronizowane w czasie rzeczywistym. Pracuj z uczniem bez żadnych opóźnień.' iconUrl='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/elements/features/sync.svg'/>
                            <IndexFeature title='Szybkie udostępnianie' subtitle='Udostępniaj zarówno pojedyncze tablice jak i całe foldery. Wystarczy jedno kliknięcie. Uczeń nie musi tworzyć konta.' iconUrl='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/elements/features/share.svg'/>
                            <IndexFeature title='Nieograniczone możliwości' subtitle='Różne tła? Różne kształty? Dodawanie obrazów? Tekst? Wiele stron? Nie ma problemu.' iconUrl='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/elements/features/unlimited.svg'/>
                            <IndexFeature title='Prosty eksport' subtitle='Wyeksportuj całą tablicę w wysokiej jakości. Wystarczy jedno kliknięcie.' iconUrl='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/elements/features/export.svg'/>
                            <IndexFeature title='Świetna organizacja' height='48px' subtitle='Dodawaj tablice do konkretnego ucznia. Opisuj swoich uczniów oraz zajęcia i utrzymuj porządek.' iconUrl='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/elements/features/organization.svg'/>
                            <IndexFeature title='Brak limitów' subtitle='Dodawaj nieograniczoną liczbę uczniów oraz tablic. Wrzucaj tyle zdjęć, ile chcesz. Pracuj z uczniami bez żadnych obaw.' iconUrl='https://tablicanakorki-public.s3.eu-central-1.amazonaws.com/web/elements/features/no-limits.svg'/>
                        </div>
                </MainDesign>
            </>
        </React.Fragment>
    );
}

