import TooltipWithHoverItem from "./TooltipWithHoverItem";

interface NumbersItemProps {
    metricValue: number | string
    metricTitle: string
    smallerText?: boolean
    tooltipTextAtTheEnd?: string
    adminPanel?: boolean
}

export default function NumbersItem(props: NumbersItemProps) {
    const {metricValue, metricTitle, smallerText, tooltipTextAtTheEnd, adminPanel} = props;
    return <div className={adminPanel? 'col-12 col-md-6 col-lg-3' : 'col-6'}>
        <div className='text-center'>
            <h2
                className={`mb-2 font-heading text-success ${smallerText ? 'fs-6' : 'fs-5'}`}
                style={{letterSpacing: '-1px'}}
            >
                {metricValue}
            </h2>
            <p className='mb-0 fs-9 text-secondary-light fw-medium'>
                {metricTitle}
                {
                    tooltipTextAtTheEnd && <TooltipWithHoverItem text={tooltipTextAtTheEnd}/>
                }
            </p>
        </div>
    </div>
}