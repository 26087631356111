import MainDesign from "../../../components/ui/MainDesign";

export default function PrivacyPolicyPage() {
    return <MainDesign containerStyles="container py-32">
        <div className='mb-12 text-center mw-md-2xl mx-auto'>
            <span className='badge bg-success-dark text-success text-uppercase shadow'>
              Regulacje
            </span>
            <h1
                className='font-heading mb-4 fs-5 text-white'
                style={{letterSpacing: '-1px'}}
            >
                Polityka prywatności
            </h1>
        </div>

        <div className='mw-md-2xl mx-auto'>
            <h3 className='mb-4 font-heading fs-7 text-white fw-semibold'>
                I. Postanowienia ogólne
            </h3>
            <ol className='mb-6 fs-10 text-secondary-light'>
                <li className='mb-2'>
                    Niniejsza Polityka ochrony prywatności określa sposób zbierania, przetwarzania i przechowywania
                    danych osobowych koniecznych do świadczenia usług drogą elektroniczną za pośrednictwem serwisu
                    internetowego w domenie TABLICANAKORKI.PL (dalej: Serwis).
                </li>
                <li className='mb-2'>
                    Administratorem danych osobowych Użytkowników jest PRODUKACJA SPÓŁKA Z OGRANICZONĄ
                    ODPOWIEDZIALNOŚCIĄ, NIP 8992990210, REGON 528312950, ul. Wyścigowa 63, 53-011 Wrocław, Polska
                    (dalej: Administrator).
                </li>
                <li className='mb-2'>
                    Dane osobowe przetwarzane są zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE)
                    2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
                    danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
                    95/46/WE (ogólne rozporządzenie o ochronie danych) (dalej: RODO).
                </li>
                <li className='mb-2'>
                    Zbierane przez Administratora dane będą:
                    <ul>
                        <li className='mb-1 mt-1'>
                            przetwarzane zgodnie z prawem,
                        </li>
                        <li className='mb-1'>
                            przetwarzane w wyraźnie określonych celach i niepoddawane dalszemu przetwarzaniu
                            niezgodnemu z tymi celami,
                        </li>
                        <li className='mb-1'>
                            merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane,
                        </li>
                        <li className='mb-1'>
                            przechowywane nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania.
                        </li>
                    </ul>
                </li>
            </ol>

            <h3 className='mb-4 font-heading fs-7 text-white fw-semibold'>
                II. Cel i podstawy prawne przetwarzania danych
            </h3>

            <ol className='mb-6 fs-10 text-secondary-light'>
                <li className='mb-2'>
                    Administrator przetwarza dane osobowe niezbędne do świadczenia i rozwoju oferowanych usług
                    dostępnych za pośrednictwem Serwisu oraz poszczególnych jego funkcjonalności.
                </li>
                <li className='mb-2'>
                    Dane osobowe przetwarzane będą w następujących celach:
                    <ol>
                        <li className='mb-1 mt-1'>
                            rejestracji konta, weryfikacji tożsamości Użytkownika oraz realizacji umowy o świadczeniu
                            usług
                            drogą elektroniczną zgodnie z ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą
                            elektroniczną, w tym w szczególności poprzez zapewnienie możliwości korzystania z konta
                            Użytkownika – na podstawie akceptacji warunków Regulaminu (art. 6 ust. 1 lit. b RODO);
                        </li>
                        <li className='mb-1'>
                            komunikacji z Użytkownikiem w celu dostarczeniu mu niezbędnych informacji oraz budowania
                            pozytywnych i rzetelnych relacji z Nim, co stanowi prawnie uzasadniony interes
                            Administratora (art. 6 ust. 1 lit. f RODO);
                        </li>
                        <li className='mb-1'>
                            promowania przez Administratora produktów i/lub usług własnych oraz swoich Partnerów
                            poprzez kierowanie drogą elektroniczną Informacji marketingowych (newsletter), o ile
                            Użytkownik wyraził zgodę na otrzymywanie takich powiadomień za pośrednictwem e-mail (art.
                            6 ust. 1 lit. a RODO);
                        </li>
                        <li className='mb-1'>
                            w celach analitycznych i statystycznych na podstawie prawnie uzasadnionego interesu
                            Administratora polegającego na prowadzeniu weryfikacji aktywności Użytkowników i ich
                            preferencji dla optymalizacji usług i produktów oraz stosowanych funkcjonalności Serwisu
                            (art. 6 ust. 1 lit. f RODO);
                        </li>
                        <li className='mb-1'>
                            ewentualnego ustalenia, dochodzenia roszczeń lub obrony przed nimi na podstawie prawnie
                            uzasadnionego interesu Administratora polegającego na ochronie jej praw (art. 6 ust. 1 lit.
                            f RODO).
                        </li>
                    </ol>
                </li>
                <li className='mb-2'>
                    W każdym z wymienionych powyżej przypadków (ust 2) podanie danych jest dobrowolne, jednak
                    konieczne do zawarcia umowy lub skorzystania z innych funkcjonalności Serwisu.
                </li>
            </ol>

            <h3 className='mb-4 font-heading fs-7 text-white fw-semibold'>
                III. Okres przetwarzania danych osobowych
            </h3>

            <ol className='mb-6 fs-10 text-secondary-light'>
                <li className='mb-2'>
                    Dane osobowe będą przetwarzane przez okres, w którym osoba pozostaje aktywnym Użytkownikiem
                    Serwisu (posiada konto Użytkownika), a po tym czasie przez okres niezbędny do zachowania zgodności
                    z przepisami prawa, dochodzenia lub obrony przed ewentualnymi roszczeniami, jednak nie dłużej niż
                    3 lat liczone od dnia rozwiązania umowy o świadczeniu usług drogą elektroniczną.
                </li>
                <li className='mb-2'>
                    Dane przetwarzane na podstawie zgody będą przetwarzane do czasu wycofania udzielonej zgody z
                    zastrzeżeniem, że wycofanie tej zgody nie ma wpływu na zgodność przetwarzania danych, którego
                    dokonano przed tym wycofaniem.
                </li>
            </ol>
            <h3 className='mb-4 font-heading fs-7 text-white fw-semibold'>
                IV. Informacje o przetwarzaniu
            </h3>
            <ol className='mb-6 fs-10 text-secondary-light'>
                <li className='mb-2'>
                    Dane osobowe w zależności od celu przetwarzania mogą zostać ujawnione:
                    <ul>
                        <li className='mb-1 mt-1'>
                            podmiotom powiązanym z Administratorem
                        </li>
                        <li className='mb-1'>
                            podmiotom współpracującym z Administratorem
                        </li>
                        <li className='mb-1'>
                            podwykonawcom, w szczególności podmiotom dostarczającym i obsługującym wybrane
                            systemy i rozwiązania informatyczne
                        </li>
                        <li className='mb-1'>
                            podmiotom obsługującym płatności internetowe
                        </li>
                        <li className='mb-1'>
                            kancelariom prawnym
                        </li>
                    </ul>
                </li>
                <li className='mb-2'>
                    Dane osobowe przetwarzane przez Administratorem mogą być przekazywane poza Europejski
                    Obszar Gospodarczy tylko i wyłącznie w przypadku korzystania z systemów informatycznych u podmiotów
                    mających siedzibę poza Europejskim Obszarem Gospodarczym.
                </li>
            </ol>

            <h3 className='mb-4 font-heading fs-7 text-white fw-semibold'>
                V. Prawa osób, których dane dotyczą
            </h3>

            <ol className='mb-6 fs-10 text-secondary-light'>
                <li className='mb-2'>
                    Użytkownik Serwisu ma prawo:
                    <ul>
                        <li className='mb-1 mt-1'>
                            dostępu do treści swoich danych osobowych
                        </li>
                        <li className='mb-1'>
                            sprostowania danych
                        </li>
                        <li className='mb-1'>
                            usunięcia danych
                        </li>
                        <li className='mb-1'>
                            ograniczenia przetwarzania danych
                        </li>
                        <li className='mb-1'>
                            przenoszenia danych
                        </li>
                        <li className='mb-1'>
                            wniesienia sprzeciwu wobec przetwarzania odbywającego się na podstawie prawienie
                            usprawiedliwionego interesu administratora
                        </li>
                        <li className='mb-1'>
                            wycofania zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania,
                            którego dokonano na podstawie tej zgody przed jej wycofaniem
                        </li>
                    </ul>
                </li>
                <li className='mb-2'>
                    Zapytania dotyczące danych należy kierować na adres mailowy kontakt@tablicanakorki.pl.
                </li>
                <li className='mb-2'>
                    Użytkownik ma prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych w sytuacji,
                    w której uzna, że przetwarzanie narusza jego prawa i wolności.
                </li>
                <li className='mb-2'>
                    W procesie przetwarzania danych nie dochodzi do zautomatyzowanego podejmowania decyzji w tym
                    do profilowania.
                </li>
            </ol>

            <h3 className='mb-4 font-heading fs-7 text-white fw-semibold'>
                VI. Pliki cookies
            </h3>

            <ol className='mb-6 fs-10 text-secondary-light'>
                <li className='mb-2'>
                    Wykorzystujemy pliki cookies w celu poprawy doświadczeń użytkowników oraz
                    optymalizacji funkcjonalności serwisu. Zbieramy informacje statystyczne i analityczne za pomocą
                    Google Analytics oraz Microsoft Clarity.
                </li>
                <li className='mb-2'>
                    Cel wykorzystywania ciasteczek:
                    <ul>
                        <li className='mb-1 mt-1'>
                            Analiza i statystyka: Za pomocą plików cookies zbieramy anonimowe dane dotyczące sposobu
                            korzystania ze strony przez użytkowników (np. najczęściej odwiedzane strony, czas
                            spędzony na stronie). Dzięki tym informacjom możemy analizować wzorce użytkowania strony
                            internetowej i na tej podstawie ulepszać strukturę oraz zawartość serwisu.
                        </li>
                        <li className='mb-1'>
                            Google Analytics: Wykorzystujemy Google Analytics, narzędzie analityczne firmy Google,
                            które pomaga nam zrozumieć, jak użytkownicy wchodzą na stronę i z niej korzystają. Google
                            Analytics wykorzystuje pliki cookies do zbierania informacji o interakcjach użytkowników na
                            stronie w sposób anonimowy.
                        </li>
                        <li className='mb-1'>
                            Microsoft Clarity: Wykorzystujemy Microsoft Clarity, narzędzie do analizy zachowań
                            użytkowników, które używa plików cookies do rejestrowania anonimowych danych o interakcjach
                            użytkowników z serwisem. Dzięki Microsoft Clarity, możemy analizować ruch użytkowników i
                            identyfikować problemy z użytecznością serwisu, co pomaga nam w jego ciągłym udoskonalaniu.
                        </li>
                    </ul>
                </li>
                <li className='mb-2'>
                    Zarządzanie plikami cookies: Użytkownik ma możliwość zarządzania plikami cookies, w tym ich
                    blokowania lub usuwania, za pomocą ustawień swojej przeglądarki internetowej. Należy jednak
                    pamiętać, że ograniczenie wykorzystania plików cookies może wpłynąć na niektóre funkcjonalności
                    strony internetowej.
                </li>
            </ol>

            <h3 className='mb-4 font-heading fs-7 text-white fw-semibold'>
                VII. Postanowienia końcowe
            </h3>

            <ol className='mb-6 fs-10 text-secondary-light'>
                <li className='mb-2'>
                    Administrator zastrzega sobie prawo do wprowadzenia zmian w niniejszej Polityce prywatności
                    jednocześnie zapewnia, że prawa Użytkowników wynikające z niniejszego dokumenty nie zostaną
                    ograniczone.
                </li>
                <li className='mb-2'>
                    O wszelkich zmiana w Polityce prywatności Użytkownik zostanie poinformowany poprzez komunikat
                    dostępny w Serwisie.
                </li>
            </ol>

            <div className='mt-8 text-center mw-md-2xl mx-auto'>
                <span className='text-success fw-medium'>
                    Ostatnia aktualizacja: 11.12.2024
                </span>
            </div>
        </div>
    </MainDesign>
}