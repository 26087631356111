import Heading from "../../ui/Heading";
import React from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {Code, CodeType} from "../../../model/Code";
import {useSubmit} from "react-router-dom";
import TooltipWithHoverItem from "../../ui/element/item/TooltipWithHoverItem";

interface AdminListCodesProps {
    codes: Code[]
}

export default function AdminListCodes({codes}: AdminListCodesProps) {
    const submit = useSubmit();

    function toggleCodeActive(code: string) {
        submit({
            code: code,
            requestType: "TOGGLE_ACTIVE"
        }, {
            method: "POST",
        });
    }

    function createCodeDetails(code: Code) {
        if (code.codeType === CodeType.DISCOUNT) {
            return `Nowa cena: ${code.discountedPrice} zł`;
        } else if (code.codeType === CodeType.FREE_SUBSCRIPTION) {
            return `Subskrypcja: ${code.subscriptionDays} dni.`;
        } else {
            return "";
        }
    }

    return <>
        <Heading title='Wszystkie kody' badgeText='Lista kodów'/>
        <div style={{overflowX: "auto"}} className='text-center'>
            <table className="table bg-light-dark text-white px-1 text-center mx-auto fs-11 rounded"
                   style={{boxSizing: "unset", overflowX: "visible"}}>
                <thead>
                <tr>
                    <th scope="col">Kod</th>
                    <th scope="col">Typ kodu</th>
                    <th scope="col">Utworzono</th>
                    <th scope="col">Użycia</th>
                    <th scope="col">Ważny do</th>
                    <th scope="col">Szczegóły</th>
                    <th scope="col">Info</th>
                    <th scope="col">Akcja</th>
                </tr>
                </thead>
                <tbody>
                {
                    codes.sort((u1, u2) => u2.createdAt.localeCompare(u1.createdAt)).map((code: Code) => (
                        <tr key={code.code} className={code.isActive ? 'text-white' : 'text-secondary-light'}>
                            <th scope="row">{code.code}</th>
                            <td>{code.codeType}</td>
                            <td>{code.createdAt.split("T")[0]}</td>
                            <td>{code.usages}{code.maxUsages ? `/${code.maxUsages}` : ''}</td>
                            <td>{code.expirationDate ?? '-'}</td>
                            <td>{createCodeDetails(code)}</td>
                            <td>{ code.comment ? <TooltipWithHoverItem customBgHex='#F59E0B' text={code.comment ?? '-'}/> : '-' } </td>
                            <td><AnimatedButton
                                onClick={() => {
                                    toggleCodeActive(code.code);
                                }}
                                className={`btn ${code.isActive ? 'btn-danger' : 'btn-success'} btn-sm ms-2 flex-grow-0`}>{code.isActive ? 'Dezaktywuj' : 'Aktywuj'}</AnimatedButton>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    </>
}