import React, {useState} from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {useSubmit} from "react-router-dom";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import Heading from "../../ui/Heading";
import AdminOfferNavbar from "./AdminOfferNavbar";
import CustomSelect from "../../ui/form/CustomSelect";
import FormInput from "../../ui/form/FormInput";
import FormTextArea from "../../ui/form/FormTextArea";
import {CodeType} from "../../../model/Code";

export default function AdminGenerateCodes() {
    const {isSubmitting} = useSubmitFormAction('/admin/oferta', null, async (body) => {
        await navigator.clipboard.writeText(body);
    }, false);

    const [codeType, setCodeType] = useState<CodeType>(CodeType.DISCOUNT);
    const [code, setCode] = useState('');
    const [maxUsages, setMaxUsages] = useState(1);
    const [expirationDate, setExpirationDate] = useState('');
    const [comment, setComment] = useState<string>('');

    // dependent on CodeType
    const [subscriptionDays, setSubscriptionDays] = useState(31);
    const [discountedPrice, setDiscountedPrice] = useState(19.99);

    const submit = useSubmit();

    function createCode() {
        submit({
            code: code,
            codeType: codeType,
            maxUsages: maxUsages,
            expirationDate: expirationDate,
            comment: comment,
            subscriptionDays: subscriptionDays,
            discountedPrice: discountedPrice,
            requestType: "CREATE"
        }, {
            method: "POST",
        });
    }

    return <>
        <Heading title='Zarządzaj kodami' badgeText='oferta'/>
        <AdminOfferNavbar/>
        <hr className='text-white'/>
        <div className='mb-12 row g-8 align-items-center'>
            <div className='col-7 col-md-2 mt-8'>
                <div className='form-group'>
                    <label
                        className='mb-2 text-white fw-bold'
                        htmlFor='modalInput2-1'
                    >
                        Rodzaj kodu
                    </label>
                    <CustomSelect
                        value={codeType}
                        onChange={(e) => {
                            setCodeType(e.target.value as CodeType);
                        }}
                        customStyles="form-control form-control-lg w-100 bg-dark text-white p-4"
                    >
                        <option value={CodeType.DISCOUNT}>Zniżka</option>
                        <option value={CodeType.FREE_SUBSCRIPTION}>Bezpłatna subskrypcja</option>
                    </CustomSelect>
                </div>
            </div>

            <div className='col-5 col-md-1 mt-8'>
                <FormInput label='Użycia' placeholder={'1'} value={maxUsages}
                           onChange={(value) => setMaxUsages(Number(value))} type='number'/>
            </div>

            <div className='col-12 col-md-3 mt-8'>
                <FormInput label='Kod (opcjonalnie)' placeholder={'MINUS10'} value={code}
                           onChange={(value) => setCode(value)}/>
            </div>
            <div className='col-12 col-md-3 mt-8'>
                <FormInput label='Data wygaśnięcia (opcjonalnie)' placeholder={'2023-12-31'} value={expirationDate}
                           onChange={(value) => setExpirationDate(value)} type='date'/>
            </div>
            <div className='col-12 col-md-2 mt-8'>
                <FormInput label={codeType === CodeType.DISCOUNT ? "Nowa cena (PLN)" : "Subskrypcja (w dniach)"} placeholder={"3"}
                           type='number'
                           value={codeType === CodeType.DISCOUNT ? discountedPrice : subscriptionDays}
                           onChange={(value) => {
                               if (codeType === CodeType.DISCOUNT) {
                                   setDiscountedPrice(Number(value))
                               } else if (codeType === CodeType.FREE_SUBSCRIPTION) {
                                   setSubscriptionDays(Number(value))
                               }
                           }}/>
            </div>
            <div className='col-12 col-md-5 mt-8'>
                <FormTextArea label='Dodatkowy komentarz' placeholder='Opisz, co to za kod...' value={comment}
                              onChange={(value) => setComment(value)} rows={4}/>
            </div>
            <div className='mt-4'>
                <AnimatedButton
                    className='btn btn-lg btn-success py-3 px-4 h-100 shadow'
                    onClick={() => {
                        createCode();
                    }}
                    disabled={isSubmitting}
                >
                    Wygeneruj kod
                </AnimatedButton>
            </div>
        </div>
    </>
}