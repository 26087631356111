import {HttpMethod, HttpService} from "./HttpService";
import {CancelNewsletterRequest, User} from "../model/tutor/User";

export class UserService {
    public static async getUser(): Promise<User> {
        return HttpService.sendRequest<User>(
            HttpMethod.GET,
            `/user`,
        );
    }

    public static async cancelNewsletter(request: CancelNewsletterRequest): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            `/cancel-newsletter`,
            JSON.stringify(request),
        );
    }
}