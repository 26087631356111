import AnimatedLink from "../../ui/motion/AnimatedLink";
import {useLocation} from "react-router-dom";

const selectedItemStyles = 'nav-link py-2 px-4 fs-12 fw-bold text-success link-success bg-success bg-opacity-25 shadow'
const otherItemStyles = 'nav-link py-2 px-4 fs-12 fw-bold text-secondary link-secondary-light'

export default function AdminOfferNavbar() {
    const location = useLocation();

    return <div className='mw-4xl mx-auto'>
        <ul className='mb-8 nav nav-pills'>
            <li>
                <AnimatedLink className={location.pathname === '/admin/oferta' ? selectedItemStyles : otherItemStyles}
                              to='/admin/oferta'>
                    Utwórz użytkownika
                </AnimatedLink>
            </li>
            <li>
                <AnimatedLink
                    className={location.pathname === '/admin/oferta/kody' ? selectedItemStyles : otherItemStyles}
                    to='/admin/oferta/kody'>
                    Zarządzaj kodami
                </AnimatedLink>
            </li>
        </ul>
    </div>
}