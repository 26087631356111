import {motion} from "framer-motion";
import {ReactNode} from "react";

interface MainDesignProps {
    children: ReactNode;
    childrenOutsideRelativePosition?: ReactNode;
    minHeight?: string;
    containerStyles?: string;
    removeSectionOverflowHidden?: boolean;
    hideBackgroundImage?: boolean;
    minHeightChildrenDiv?: string;
    disableAnimation?: boolean;
    id?: string
}

export default function MainDesign(props: MainDesignProps) {
    const {
        children,
        childrenOutsideRelativePosition,
        minHeight,
        containerStyles,
        removeSectionOverflowHidden,
        hideBackgroundImage,
        minHeightChildrenDiv,
        disableAnimation,
        id
    } = props;

    return (
        <section
            id={id}
            className={`position-relative bg-secondary-dark ${removeSectionOverflowHidden ? '' : 'overflow-hidden'}`}
            style={{
                backgroundImage: hideBackgroundImage ? undefined : 'url(https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/pattern-dark.png)',
                minHeight: `${minHeight ? minHeight : '70vh'}`,
                backgroundSize: 'auto', // Adjust this to 'auto' to maintain the original size of the image
                backgroundRepeat: 'repeat', // If the image is intended to repeat, use 'repeat'
                backgroundPosition: 'center center', // Adjust the position to center the image
            }}
        >
            {
                !hideBackgroundImage && <div
                    className="position-absolute top-0 start-0 h-100 w-100"
                    style={{
                        background: 'radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)',
                    }}
                />
            }

            {childrenOutsideRelativePosition}
            <motion.div
                className={`position-relative ${containerStyles}`}
                initial={disableAnimation ? undefined : {opacity: 0, y: 50}}
                animate={disableAnimation ? undefined : {opacity: 1, y: 0}}
                style={removeSectionOverflowHidden ? {minHeight: minHeightChildrenDiv ? minHeightChildrenDiv : undefined} : {
                    zIndex: 1,
                    minHeight: minHeightChildrenDiv ? minHeightChildrenDiv : undefined,
                    willChange: "auto"
                }}
            >
                {children}
            </motion.div>
        </section>
    );
}