import React from "react";

interface FormTextAreaProps {
    label: string
    placeholder: string
    value: string
    onChange: React.Dispatch<React.SetStateAction<string>>
    rows: number
}

export default function FormTextArea(props: FormTextAreaProps) {
    const {label, placeholder, value, onChange, rows} = props;

    return <div className='form-group'>
        <label
            className='mb-2 text-white fw-bold'
            htmlFor={`${label}-${value}`}
        >
            {label}
        </label>
        <textarea
            className='input-lg form-control shadow bg-dark'
            id={`${label}-${value}`}
            placeholder={placeholder}
            rows={rows}
            value={value}
            onChange={(event) => {
                onChange(event.target.value)
            }}
            style={value ? {color: 'white'} : {}}
        ></textarea>
    </div>
}