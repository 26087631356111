import React from 'react';
import MainDesign from "../../ui/MainDesign";
import FaqItem from "../../ui/element/item/FaqItem";

export default function IndexFAQ() {
    return (
        <React.Fragment>
            <>
                <MainDesign containerStyles='py-20 container'>
                        <div className="mb-32 text-center mw-md-4xl mx-auto">
                            <span className="badge mb-4 bg-success-dark text-success text-uppercase shadow">Faq</span>
                            <h2 className="font-heading mb-4 fs-5 text-white" style={{letterSpacing: '-1px'}}>Najczęściej zadawane
                                pytania</h2>
                            <p className="mb-0 fs-9 fw-medium text-secondary-light px-2">Masz jakieś pytania? Znajdź odpowiedź poniżej
                                lub skontaktuj się z nami.</p>
                        </div>
                        <div className="mw-md-6xl mx-auto">
                            <div className="row g-8">
                                <FaqItem question='Czy mogę korzystać z aplikacji za darmo?' answer='Oczywiście. Po zarejestrowaniu się możesz stworzyć 3 uczniów oraz dodawać w sumie 15 tablic miesięcznie. Te tablice będą mogły być edytowane tylko przez 24h od ich utworzenia. Jest to świetna opcja dla korepetytorów prowadzących zajęcia raz w tygodniu z trzema uczniami.'/>
                                <FaqItem question='Co wyróżnia Tablicę Na Korki od innych tablic?' answer='Tablica Na Korki jest specjalnie przystosowana do prowadzenia zajęć online i potrzeb nauczycieli. Jest prosta i intuicyjna w obsłudze. Umożliwia zarządzanie uczniami i tablicami w najefektywniejszy sposób. '/>
                                <FaqItem question='Czy uczeń musi tworzyć konto, aby korzystać z aplikacji?' answer='Nie. Wystarczy udostępnić mu link do tablicy i uczeń od razu może zacząć z niej korzystać. Co więcej, jednym kliknięciem możesz udostępnić uczniowi folder zawierający wszystkie jego tablice.'/>
                                <FaqItem question='Czy są jakieś limity?' answer='W bezpłatnej wersji występują pewne ograniczenia (opisane wyżej). Kupując subskrypcję, możesz dodawać dowolną ilość uczniów i tablic, edytować istniejące tablice przez cały czas, a także przesyłać tyle plików, ile chcesz. Tablice są dostępne przez 180 dni.'/>
                                <FaqItem question='Ile kosztuje subskrypcja i co jest w niej zawarte?' answer='Subskrypcja kosztuje 29.99zł miesięcznie. Po każdym miesiącu możesz podjąć decyzję, czy chcesz dalej korzystać z Tablicy Na Korki, czy nie.'/>
                                <FaqItem question='Co się stanie, jak nie przedłużę subskrypcji?' answer='Automatycznie wrócisz do korzystania z aplikacji w wersji bezpłatnej. Nadmiarowe tablice oraz uczniowie nie zostaną usunięci, jednak w przypadku przekroczenia limitów nie będziesz mieć możliwości dodawania nowych ucziów lub tablic.'/>
                                <FaqItem question='Jak działa program poleceń?' answer='Jeżeli ktoś wykupi miesięczną subskrypcję korzystając z Twojego kodu, oboje otrzymacie dodatkowe 31 dni subskrypcji całkowicie za darmo. Swój kod znajdziesz w zakładce "Program Poleceń" po zalogowaniu.'/>
                                <FaqItem question='Czy mogę polecić więcej niż jedną osobę?' answer='Oczywiście. Jeżeli na przykład polecisz 12 osób i każda z nich wykupi subskrypcję przynajmniej raz, otrzymasz 12 miesięcy subskrypcji całkowicie za darmo.'/>
                                <FaqItem question='Czy muszę podawać swoją kartę podczas zakupu?' answer='Oczywiście, że nie. To Ty decydujesz w jaki sposób płacisz (BLIK / bank). Samemu decydujesz CZY i KIEDY przedłużysz subskrypcję. Subskrypcja NIE JEST przedłużana automatycznie.'/>
                            </div>
                        </div>
                </MainDesign>
            </>
        </React.Fragment>
    );
}

