import React, {useState} from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {useSubmit} from "react-router-dom";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import Heading from "../../ui/Heading";
import AdminOfferNavbar from "./AdminOfferNavbar";
import FormTextArea from "../../ui/form/FormTextArea";
import FormInput from "../../ui/form/FormInput";
import {CreateTempUserResponse} from "../../../model/admin/Offer";

export default function AdminOffer() {
    const {isSubmitting} = useSubmitFormAction('/admin/oferta', null, async (body) => {
        const response = JSON.parse(body) as CreateTempUserResponse
        setTempEmail(response.email)
        setTempPassword(response.password)
    }, false, undefined, true);

    const [tempEmail, setTempEmail] = useState<string | null>(null);
    const [tempPassword, setTempPassword] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [subscriptionDays, setSubscriptionDays] = useState(31);
    const [comment, setComment] = useState<string>('');

    const submit = useSubmit();

    function generateLink() {
        submit({
            name: name,
            email: email,
            comment: comment,
            subscriptionDays: subscriptionDays
        }, {
            method: "POST",
        });
    }

    const isInputValid = name.length > 0 && comment.length > 0;

    return <>
        <Heading title='Utwórz użytkownika' badgeText='oferta'/>
        <AdminOfferNavbar/>
        <hr className='text-white'/>
        <div className='mb-12 row g-8 align-items-center'>
            <div className='col-12 col-md-3 mt-8'>
                <FormInput label=' Imię klienta / klientki' placeholder={'Marysia'} value={name}
                           onChange={(value) => setName(value)} type='tetx'/>
            </div>
            <div className='col-12 col-md-3 mt-8'>
                <FormInput label='Adres email (opcjonalnie)' placeholder={'test@test.pl'} value={email}
                           onChange={(value) => setEmail(value)} type='email'/>
            </div>
            <div className='col-12 col-md-3 mt-8'>
                <FormInput label='Subskrypcja (w dniach)' placeholder={'3'} value={subscriptionDays}
                           onChange={(value) => setSubscriptionDays(Number(value))} type='number'/>
            </div>
            <div className='col-12 col-md-5 mt-8'>
                <FormTextArea label='Komentarz' placeholder='Opisz, co to za użytkownik...' value={comment}
                              onChange={(value) => setComment(value)} rows={3}/>
            </div>
            <p className='text-white fw-medium mt-2'>Jeżeli nie podasz adresu email, zostanie on wygenerowany automatycznie.</p>
            <div className='mt-4'>
                <AnimatedButton
                    className='btn btn-lg btn-success py-3 px-4 h-100 shadow'
                    onClick={() => {
                        setTempPassword(null);
                        generateLink();
                    }}
                    disabled={isSubmitting || !isInputValid}
                    customScale={1}
                >
                    Wygeneruj użytkownika
                </AnimatedButton>
            </div>

            {tempPassword && tempEmail &&
                <p rel="noopener noreferrer mt-2" className='text-white'
                >Utworzono nowego użytkownika: <br/>
                    <b>Email</b>: <i>{tempEmail}</i> <br/>
                    <b>Hasło</b>: <i>{tempPassword}</i></p>}
        </div>
    </>
}