import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {BoardResponse} from "../../../model/tutor/Board";
import {useSync} from "@tldraw/sync";
import {multiplayerAssets, unfurlBookmarkUrl} from "../../../pages/common/Whiteboard";
import {
    DefaultQuickActions,
    DefaultQuickActionsContent,
    Editor,
    exportToBlob, TLComponents, Tldraw,
    TldrawUiMenuItem, TLUiActionsContextType, TLUiOverrides, TLUiTranslationKey,
    useEditor
} from "tldraw";
import {BoardService} from "../../../service/BoardService";
import {CustomMainMenu} from "./CustomMainMenu";
import {CustomGrid} from "./CustomGrid";
import AddOrEditBoardModal from "../../tutor/board/AddOrEditBoardModal";
import BoardShareModal from "../../tutor/board/BoardShareModal";
import BoardInfoModal from "../../tutor/board/BoardInfoModal";

interface CollaborativeBoardProps {
    boardResponse: BoardResponse
}

const BACKEND_URL = process.env.REACT_APP_API_URL

export default function CollaborativeBoard(props: CollaborativeBoardProps) {
    const {boardResponse} = props;

    const {roomId} = useParams();

    const [editBoardModalOpen, setEditBoardModalOpen] = React.useState(false);
    const [shareBoardModalOpen, setShareBoardModalOpen] = React.useState(false);
    const [boardInfoModalOpen, setBoardInfoModalOpen] = React.useState(false);

    const [redirectAfterEditUrl, setRedirectAfterEditUrl] = React.useState<string>("/");

    const editorRef = useRef<Editor | null>(null);
    const [editorReady, setEditorReady] = useState(false);

    const store = useSync({
        // We need to know the websocket's URI...
        uri: `${BACKEND_URL}/connect/${roomId}`,

        // ...and how to handle static assets like images & videos
        assets: multiplayerAssets,
    })

    // Upload room-preview
    useEffect(() => {
        let initialTimeoutId: any;
        let intervalId: any;

        if (boardResponse.isTutor && editorReady) {
            const uploadFunction = async () => {
                try {
                    const shapeIds = editorRef.current!!.getCurrentPageShapeIds();
                    if (shapeIds.size === 0) {
                        // No shapes on the canvas
                        return;
                    }

                    // Export the board to a PNG blob
                    const blob = await exportToBlob({
                        editor: editorRef.current!!,
                        // @ts-ignore
                        ids: [...shapeIds],
                        format: 'png',
                        opts: {background: true, scale: 0.25, darkMode: true},
                    });

                    // Check if the blob size is under 2MB (2 * 1024 * 1024 bytes)
                    if (blob.size <= 2 * 1024 * 1024) {
                        const roomId = window.location.href.split("/t/")[1]?.split("?")[0];
                        await BoardService.uploadRoomPreview(roomId, blob);
                    }
                } catch (error) {
                    console.error('Error exporting and uploading board image:', error);
                }
            };

            // Initial delay of 30 seconds
            initialTimeoutId = setTimeout(() => {
                uploadFunction();

                // Then set up interval every 5 * 60 seconds
                intervalId = setInterval(uploadFunction, 5 * 60000);
            }, 30000); // 30000 milliseconds = 30 seconds
        }

        // Cleanup function to clear the interval and timeout
        return () => {
            if (initialTimeoutId) {
                clearTimeout(initialTimeoutId);
            }
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [boardResponse, editorReady]);


    function CustomQuickActions() {
        const editor = useEditor()
        return (
            <DefaultQuickActions>
                {/*@ts-ignore*/}
                <DefaultQuickActionsContent/>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon="share-1" onSelect={() => setShareBoardModalOpen(true)}/>
                </div>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon="info-circle" onSelect={() => setBoardInfoModalOpen(true)}/>
                </div>
                <div style={{cursor: 'pointer'}}>
                    <TldrawUiMenuItem id="code" icon={'fill-pattern'} onSelect={() => {
                        editor.updateInstanceState({isGridMode: !editor.getInstanceState().isGridMode})
                    }
                    }/>
                </div>
            </DefaultQuickActions>
        )
    }

    const overrides: TLUiOverrides = {
        //[a]
        actions(_editor, actions): TLUiActionsContextType {
            const newActions = {
                ...actions,
                'toggle-grid': {...actions['toggle-grid' as TLUiTranslationKey], kbd: 'x'},
                'toggle-dark-mode': {...actions['toggle-dark-mode' as TLUiTranslationKey], kbd: ''},
                'copy-as-png': {...actions['copy-as-png'], kbd: '$1'},
            }

            return newActions
        },
    }

    function getComponents(): TLComponents {
        return {
            ActionsMenu: null,
            MainMenu: () => {
                const editor = useEditor();
                return CustomMainMenu(editor, boardResponse.board.title, boardResponse.isTutor, "Zakończ lekcję", () => {
                    setEditBoardModalOpen(true)
                    setRedirectAfterEditUrl(boardResponse.board.studentId ? `/uczen/${boardResponse.board.studentId}` : '/')
                });
            },
            QuickActions: CustomQuickActions,
            Grid: ({size, ...camera}) => {
                const editor = useEditor()

                return CustomGrid(editor, size, camera);
            }
        }
    }


    return <>
        <Tldraw
            components={getComponents()}
            store={store}
            autoFocus={true}
            inferDarkMode={true}
            overrides={overrides}
            onMount={(editor) => {
                editorRef.current = editor;
                setEditorReady(true);
                // @ts-expect-error
                window.editor = editor
                if (editor.getCurrentPage().name === 'Page 1') {
                    editor.renamePage(editor.getCurrentPage(), "Strona 1")
                }
                // when the editor is ready, we need to register our bookmark unfurling service
                editor.registerExternalAssetHandler('url', unfurlBookmarkUrl)
                editor.updateInstanceState({isGridMode: false})
            }}
        />
        <AddOrEditBoardModal board={boardResponse.board} isOpen={editBoardModalOpen}
                             setOpen={setEditBoardModalOpen}
                             studentId={boardResponse.board.studentId}
                             customTitle='Wprowadź szczegóły zajęć.'
                             redirectAfterEditUrl={redirectAfterEditUrl}/>
        <BoardShareModal isOpen={shareBoardModalOpen} setOpen={setShareBoardModalOpen}
                         board={boardResponse.board}/>
        <BoardInfoModal isOpen={boardInfoModalOpen} setOpen={setBoardInfoModalOpen}
                        board={boardResponse.board}
                        isTutor={boardResponse.isTutor}
                        onEditInfoClick={() => {
                            setBoardInfoModalOpen(false);
                            setRedirectAfterEditUrl(`/t/${boardResponse.board.id}`);
                            setEditBoardModalOpen(true);
                        }}/>
    </>
}