import {Referral} from "../../../model/Code";
import React from "react";
import {formatISODateTimeString} from "../../../util/DateUtil";

interface ReferralListProps {
    referrals: Referral[]
}

export default function ReferralList({referrals}: ReferralListProps) {
    return <>
        <div style={{overflowX: "auto"}} className='text-center'>
            <table className="table bg-light-dark text-white px-1 text-center mx-auto fs-11 rounded mw-md-3xl"
                   style={{boxSizing: "unset", overflowX: "visible"}}>
                <thead>
                <tr>
                    <th scope="col">Numer</th>
                    <th scope="col">Data</th>
                    <th scope="col">Użyto przez</th>
                </tr>
                </thead>
                <tbody>
                {
                    referrals.sort((u1, u2) => u2.referredAt.localeCompare(u1.referredAt)).map((referral: Referral, count: number) => (
                        <tr key={count} className='text-white'>
                            <th scope="row">{referrals.length - count}</th>
                            <td>{formatISODateTimeString(referral.referredAt)}</td>
                            <td>{referral.name}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    </>
}