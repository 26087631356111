import React from "react";

interface FormInputProps {
    label: string
    placeholder: string
    value: string | number | readonly string[] | undefined
    onChange: React.Dispatch<React.SetStateAction<string>>
    type?: string
}

export default function FormInput(props: FormInputProps) {
    const {label, placeholder, value, onChange, type} = props;
    return <div className='form-group'>
        <label
            className='mb-2 text-white fw-bold'
            htmlFor={`${label}-${value}`}
        >
            {label}
        </label>
        <input
            className='input-lg form-control shadow-sm border-0 bg-dark'
            id={`${label}-${value}`}
            type={type ? type : 'text'}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            style={value ? {color: 'white'} : {}}
        />
    </div>
}