// useModalClose.tsx
import { useEffect, useRef } from "react";

export default function useModalClose(
    isOpen: boolean,
    setOpen: (value: boolean) => void,
    onClose?: () => void
) {
    const modalRef = useRef<HTMLFormElement>(null);

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            const handleEscape = (event: KeyboardEvent) => {
                if (event.key === "Escape") {
                    handleClose();
                }
            };

            const handleClickOutside = (event: MouseEvent) => {
                if (
                    modalRef.current &&
                    !modalRef.current.contains(event.target as Node)
                ) {
                    handleClose();
                }
            };

            window.addEventListener("keydown", handleEscape);
            window.addEventListener("mousedown", handleClickOutside);

            return () => {
                window.removeEventListener("keydown", handleEscape);
                window.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [isOpen]);

    return modalRef;
}